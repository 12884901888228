<template>
    <v-theme-provider :theme="isDarkTheme ? 'dark' : 'light'">
        <v-card elevation="12">
            <p v-if="showDates" style="position: absolute; top: 15px; left: 15px;">
                {{ job.end ? `${job.start} - ${job.end}` : `Since ${job.start}` }}
            </p>
            <div v-if="job.organization === 'Dtex Systems'" class="pl-4 pt-4 pb-2 d-flex justify-center align-center">
                <DtexLogo :height="35" :isDarkTheme="isDarkTheme" class="clickable" @click="visitExternalLink(job.url)">
                </DtexLogo>
            </div>
            <div v-if="job.organization === 'Savant'" class="pl-4 pt-4 pb-2 d-flex justify-center align-center">
                <SavantLogo :height="35" :isDarkTheme="isDarkTheme" class="clickable"
                    @click="visitExternalLink(job.url)">
                </SavantLogo>
            </div>
            <div v-if="job.organization === 'Mike\'s High Performance Software'" class="pl-4 pt-4 pb-2 d-flex justify-center align-center">
                <GradientLogo 
                    :logoText="'MHPS'"
                    :minFontSize="50"
                    :maxFontSize="50"
                    :unlimitedSize="true"
                    hexColor="#0883AE"
                    hexBottomColor="#FFF"
                    />
            </div>
            <div v-if="job.organization === 'Stealth Cybersecurity Startup'" class="pl-4 pt-4 pb-2 d-flex justify-center align-center">
                <GradientLogo :logoText="job.organization" :minFontSize="25" :maxFontSize="25" />
            </div>
            <v-img class="ml-4 mt-4 mb-2 clickable" v-if="job.logo" :src="job.logo" height="50"
                @click="visitExternalLink(job.url)"></v-img>
            <div class="d-flex flex-column align-center justify-start">
                <v-card-text v-if="showURLs && job.url" :class="[uniformFont ? undefined : 'text-medium-emphasis', 'mt-0', 'mb-0', 'pb-0', 'pt-0']">
                    {{ job.url }}
                </v-card-text>
                <v-card-text :class="[uniformFont ? undefined : 'text-medium-emphasis', 'mt-1', 'mb-0', 'pb-0', 'pt-0']">
                    {{ job.orgDescription }}
                </v-card-text>
                <v-card-text :class="[uniformFont ? undefined : 'text-high-emphasis', 'font-weight-black', 'mt-2', 'mb-0', 'pb-0', 'pt-0']">
                    {{ job.title }}
                </v-card-text>
                <v-card-text class="white mt-1 mb-1 pb-0 pt-0">
                    <ul class="ml-3">
                        <li v-for="(distinction, i) in job.distinctions" :key="i">{{
                        distinction }}</li>
                    </ul>
                </v-card-text>
            </div>
        </v-card>
    </v-theme-provider>
</template>

<script>
import DtexLogo from '../logos/DtexLogo.vue'
import SavantLogo from '../logos/SavantLogo.vue'
import GradientLogo from '../logos/GradientLogo.vue'
export default {
name: 'ExperienceItem',
    props: {
        job: {
            type: Object,
            required: true,
            default: () => ({
                organization: 'A Great Company',
                orgDescription: 'A Great Company provides financial advice and consulting services for Fortune 100 companies worldwide',
                url: 'https://www.greatcompany.com',
                logo: null,
                start: 'Sept. \'99',
                end: 'Sept. \'01',
                title: 'Sr. VP of Financial Products',
                distinctions: [
                    'Managed a lot of stuff',
                    'Made lots of things happen',
                    'Grew the business by a great amount',
                    'Hired lots of great people'
                ]
            })
        },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        showDates: {
            type: Boolean,
            required: false,
            default: false
        },
        showURLs: {
            type: Boolean,
            required: false,
            default: false
        },
        uniformFont: {
            type: Boolean,
            required: false,
            default: false
        }
    },
components: {
    DtexLogo,
    SavantLogo,
    GradientLogo
},
setup() {
    // External Links

    const visitExternalLink = (url) => {
        if (url) {
            window.open(url)
        }
    }

    return {
    // Functions
    visitExternalLink
    }
}
}
</script>

<style>

</style>