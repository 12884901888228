<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 544.6 116.6" class="c-logo" :style="{ 'height': height + 'px' }">
        <path class="c-logo__highlight" style="fill:#C00000;" d="M102.5,0.1H1.5v72.2h16.4l22.7-16H17.5V16h85.3c7.1,0,12.8,5.7,12.8,12.8v14.7
        c0,7.1-5.7,12.8-12.8,12.8H69.3v-12l-39.8,28h73c16,0,29-13,29.1-29V29.1C131.5,13.1,118.6,0.1,102.5,0.1
        C102.5,0.1,102.5,0,102.5,0.1z"></path>
        <path class="c-logo__text" :style="{ 'fill': isDarkTheme ? 'white' : 'black'}" d="M146.7,16h50.1v56.2h16V16h50.1V0H146.7V16z M495.1,40l-13.9,9.8l32,22.5H541L495.1,40z M512.1,0.1l-36.9,26l-36.8-26h-27.9
        l50.9,35.7l-51.9,36.5h27.8L540,0.1C540,0.1,512.1,0.1,512.1,0.1z M284.5,72.3h106.1v-16l-90,0.1V44.3l0,0v-0.1h90.1v-16h-90.1V16
        h90.1V0H284.6L284.5,72.3z"></path>
        <g class="c-logo__slogan" :style="{ 'fill': isDarkTheme ? 'white' : 'black'}">
            <path d="M4.3,115.9L0,99.9v-0.1c0-0.1,0-0.2,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1h2.2c0.2,0,0.4,0.1,0.4,0.3l2.7,10.8
            c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1-0.1l2.6-10.8c0-0.2,0.2-0.3,0.4-0.3h2.2c0.2,0,0.4,0.1,0.4,0.3l2.7,10.8c0,0.1,0,0.1,0.1,0.1
            c0,0,0.1,0,0.1-0.1l2.7-10.8c0-0.2,0.2-0.3,0.4-0.3h1.9c0.1,0,0.2,0,0.3,0.1s0.1,0.2,0,0.3l-4.1,16c0,0.2-0.2,0.3-0.4,0.3h-2.1
            c-0.2,0-0.4-0.1-0.4-0.3l-2.8-11.3c0-0.1,0-0.1-0.1-0.1s-0.1,0-0.1,0.1L7,115.9c0,0.2-0.2,0.3-0.4,0.3H4.7
            C4.5,116.2,4.4,116.1,4.3,115.9z"></path>
            <path
                d="M24.8,115.7c-0.9-0.5-1.6-1.2-2.1-2.1c-0.5-0.9-0.8-1.9-0.8-3.1V105c0-1.2,0.3-2.2,0.8-3.1c0.5-0.9,1.2-1.6,2.1-2.1
            s2-0.7,3.2-0.7s2.3,0.2,3.2,0.7s1.6,1.2,2.1,2.1c0.5,0.9,0.8,1.9,0.8,3.1v5.5c0,1.2-0.3,2.2-0.8,3.1c-0.5,0.9-1.2,1.6-2.1,2.1
            c-0.9,0.5-2,0.7-3.2,0.7S25.7,116.2,24.8,115.7z M30.4,113.1c0.6-0.6,0.9-1.4,0.9-2.5V105c0-1-0.3-1.9-0.9-2.5s-1.4-0.9-2.4-0.9
            s-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.5-0.9,2.5v5.6c0,1,0.3,1.8,0.9,2.5c0.6,0.6,1.4,0.9,2.4,0.9C29,114,29.8,113.7,30.4,113.1z">
            </path>
            <path d="M47.2,115.9l-3.1-6.9c0-0.1-0.1-0.1-0.2-0.1h-2.8c-0.1,0-0.1,0-0.1,0.1v6.7c0,0.3-0.1,0.4-0.4,0.4h-2
            c-0.3,0-0.4-0.1-0.4-0.4V99.6c0-0.3,0.1-0.4,0.4-0.4h6.5c1,0,1.8,0.2,2.6,0.6s1.3,1,1.8,1.7c0.4,0.7,0.6,1.6,0.6,2.6
            c0,1.1-0.3,2-0.9,2.8s-1.3,1.3-2.3,1.6c0,0-0.1,0-0.1,0.1s0,0.1,0,0.1l3.3,6.9v0.2c0,0.2-0.1,0.3-0.3,0.3h-2.1
            C47.4,116.2,47.3,116.1,47.2,115.9z M40.9,101.9v4.7c0,0.1,0,0.1,0.1,0.1h3.6c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.8
            c0-0.8-0.2-1.4-0.7-1.8c-0.5-0.5-1.1-0.7-1.9-0.7H41C41,101.8,40.9,101.8,40.9,101.9z"></path>
            <path d="M53.8,115.8v-16c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v7.1c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1-0.1l6.2-7.3
            c0.1-0.1,0.3-0.2,0.5-0.2h2.3c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.2-0.1,0.2l-5.4,6.6c0,0,0,0.1,0,0.2l5.8,9.1c0,0.1,0.1,0.1,0.1,0.2
            c0,0.2-0.1,0.2-0.3,0.2h-2.3c-0.2,0-0.4-0.1-0.5-0.3l-4.7-7.3l-0.1-0.1c0,0-0.1,0-0.1,0.1l-1.7,2c0,0.1-0.1,0.1-0.1,0.2v5
            c0,0.3-0.1,0.4-0.4,0.4h-2C54,116.2,53.8,116.1,53.8,115.8z"></path>
            <path d="M80.6,101.8h-8c-0.1,0-0.1,0-0.1,0.1v4.4c0,0.1,0,0.1,0.1,0.1H78c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4h-5.4
            c-0.1,0-0.1,0-0.1,0.1v6.7c0,0.3-0.1,0.4-0.4,0.4h-2c-0.3,0-0.4-0.1-0.4-0.4v-16c0-0.3,0.1-0.4,0.4-0.4h10.6c0.3,0,0.4,0.1,0.4,0.4
            v1.6C81,101.7,80.9,101.8,80.6,101.8z"></path>
            <path d="M86.6,115.7c-0.9-0.5-1.6-1.2-2.1-2.1c-0.5-0.9-0.8-1.9-0.8-3.1V105c0-1.2,0.3-2.2,0.8-3.1c0.5-0.9,1.2-1.6,2.1-2.1
            c0.9-0.5,2-0.7,3.2-0.7s2.3,0.2,3.2,0.7c0.9,0.5,1.6,1.2,2.1,2.1c0.5,0.9,0.8,1.9,0.8,3.1v5.5c0,1.2-0.3,2.2-0.8,3.1
            c-0.5,0.9-1.2,1.6-2.1,2.1c-0.9,0.5-2,0.7-3.2,0.7S87.5,116.2,86.6,115.7z M92.2,113.1c0.6-0.6,0.9-1.4,0.9-2.5V105
            c0-1-0.3-1.9-0.9-2.5c-0.6-0.6-1.4-0.9-2.4-0.9s-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.5-0.9,2.5v5.6c0,1,0.3,1.8,0.9,2.5
            c0.6,0.6,1.4,0.9,2.4,0.9C90.8,114,91.6,113.7,92.2,113.1z"></path>
            <path d="M109,115.9l-3.1-6.9c0-0.1-0.1-0.1-0.2-0.1h-2.8c-0.1,0-0.1,0-0.1,0.1v6.7c0,0.3-0.1,0.4-0.4,0.4h-2
            c-0.3,0-0.4-0.1-0.4-0.4V99.6c0-0.3,0.1-0.4,0.4-0.4h6.5c1,0,1.8,0.2,2.6,0.6s1.3,1,1.8,1.7c0.4,0.7,0.6,1.6,0.6,2.6
            c0,1.1-0.3,2-0.9,2.8c-0.6,0.8-1.3,1.3-2.3,1.6c0,0-0.1,0-0.1,0.1s0,0.1,0,0.1l3.3,6.9v0.2c0,0.2-0.1,0.3-0.3,0.3h-2.1
            C109.2,116.2,109.1,116.1,109,115.9z M102.7,101.9v4.7c0,0.1,0,0.1,0.1,0.1h3.6c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.8
            c0-0.8-0.2-1.4-0.7-1.8c-0.5-0.5-1.1-0.7-1.9-0.7h-3.6C102.8,101.8,102.7,101.8,102.7,101.9z"></path>
            <path d="M118.1,115.7c-0.9-0.5-1.6-1.1-2.1-2c-0.5-0.8-0.7-1.8-0.7-3v-5.9c0-1.1,0.2-2.1,0.7-3c0.5-0.8,1.2-1.5,2.1-2
            c0.9-0.5,2-0.7,3.2-0.7s2.2,0.2,3.1,0.7c0.9,0.4,1.6,1.1,2.1,1.9c0.5,0.8,0.7,1.8,0.7,2.8c0,0.2-0.1,0.3-0.4,0.3l-2,0.1h-0.1
            c-0.2,0-0.3,0-0.3-0.1v-0.1c0-0.9-0.3-1.7-0.9-2.3c-0.6-0.6-1.4-0.9-2.3-0.9c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.3-0.9,2.3v6.1
            c0,0.9,0.3,1.7,0.9,2.3c0.6,0.6,1.4,0.9,2.4,0.9s1.8-0.3,2.3-0.9c0.6-0.6,0.9-1.3,0.9-2.3c0-0.1,0-0.1,0.1-0.2
            c0.1-0.1,0.2-0.1,0.3-0.1l2,0.1c0.3,0,0.4,0.1,0.4,0.1v0.1c0,1.1-0.2,2-0.7,2.9c-0.5,0.8-1.2,1.5-2.1,1.9c-0.9,0.4-2,0.7-3.1,0.7
            C120,116.4,119,116.2,118.1,115.7z"></path>
            <path
                d="M142.2,101.8h-8.1c-0.1,0-0.1,0-0.1,0.1v4.4c0,0.1,0,0.1,0.1,0.1h5.4c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4
            h-5.4c-0.1,0-0.1,0-0.1,0.1v4.6c0,0.1,0,0.1,0.1,0.1h8.1c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4h-10.6
            c-0.3,0-0.4-0.1-0.4-0.4v-16c0-0.3,0.1-0.4,0.4-0.4h10.6c0.3,0,0.4,0.1,0.4,0.4v1.6C142.6,101.7,142.4,101.8,142.2,101.8z">
            </path>
            <path d="M154.6,115.7c-0.9-0.5-1.6-1.1-2.1-2c-0.5-0.8-0.7-1.8-0.7-3v-5.9c0-1.1,0.2-2.1,0.7-3c0.5-0.8,1.2-1.5,2.1-2
            c0.9-0.5,2-0.7,3.2-0.7s2.2,0.2,3.1,0.7c0.9,0.4,1.6,1.1,2.1,1.9c0.5,0.8,0.7,1.8,0.7,2.8c0,0.2-0.1,0.3-0.4,0.3l-2,0.1h-0.1
            c-0.2,0-0.3,0-0.3-0.1v-0.1c0-0.9-0.3-1.7-0.9-2.3c-0.6-0.6-1.4-0.9-2.3-0.9c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.3-0.9,2.3v6.1
            c0,0.9,0.3,1.7,0.9,2.3c0.6,0.6,1.4,0.9,2.4,0.9s1.8-0.3,2.3-0.9c0.6-0.6,0.9-1.3,0.9-2.3c0-0.1,0-0.1,0.1-0.2
            c0.1-0.1,0.2-0.1,0.3-0.1l2,0.1c0.3,0,0.4,0.1,0.4,0.1v0.1c0,1.1-0.2,2-0.7,2.9c-0.5,0.8-1.2,1.5-2.1,1.9c-0.9,0.4-2,0.7-3.1,0.7
            C156.5,116.4,155.5,116.2,154.6,115.7z"></path>
            <path d="M171.2,115.8v-6.3c0,0,0-0.1,0-0.2l-4.8-9.5c0-0.1-0.1-0.2-0.1-0.2c0-0.2,0.1-0.2,0.3-0.2h2.2c0.2,0,0.4,0.1,0.5,0.3
            l3.3,6.6l0.1,0.1c0,0,0.1,0,0.1-0.1l3.3-6.6c0.1-0.2,0.2-0.3,0.5-0.3h2.2c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.1-0.1,0.2l-4.9,9.5
            c0,0.1,0,0.2,0,0.2v6.3c0,0.3-0.1,0.4-0.4,0.4h-2C171.3,116.2,171.2,116,171.2,115.8z"></path>
            <path d="M191.6,107.5c0,0,0,0.1,0.1,0.1c1.6,0.8,2.4,2.1,2.4,3.9c0,1.5-0.5,2.7-1.5,3.5s-2.3,1.2-4,1.2h-6c-0.3,0-0.4-0.1-0.4-0.4
            v-16c0-0.3,0.1-0.4,0.4-0.4h5.8c1.7,0,3.1,0.4,4,1.1c0.9,0.8,1.4,1.9,1.4,3.4C193.8,105.6,193.1,106.7,191.6,107.5
            C191.6,107.4,191.6,107.5,191.6,107.5z M185,101.9v4.4c0,0.1,0,0.1,0.1,0.1h3.2c0.8,0,1.5-0.2,2-0.6c0.5-0.4,0.7-1,0.7-1.7
            s-0.2-1.3-0.7-1.7s-1.2-0.6-2-0.6h-3.2C185.1,101.8,185,101.8,185,101.9z M190.6,113.1c0.5-0.4,0.7-1.1,0.7-1.8
            c0-0.8-0.2-1.4-0.7-1.8c-0.5-0.4-1.2-0.7-2-0.7h-3.4c-0.1,0-0.1,0-0.1,0.1v4.7c0,0.1,0,0.1,0.1,0.1h3.4
            C189.4,113.8,190.1,113.5,190.6,113.1z"></path>
            <path d="M209.1,101.8H201c-0.1,0-0.1,0-0.1,0.1v4.4c0,0.1,0,0.1,0.1,0.1h5.4c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4H201
            c-0.1,0-0.1,0-0.1,0.1v4.6c0,0.1,0,0.1,0.1,0.1h8.1c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4h-10.6c-0.3,0-0.4-0.1-0.4-0.4
            v-16c0-0.3,0.1-0.4,0.4-0.4h10.6c0.3,0,0.4,0.1,0.4,0.4v1.6C209.5,101.7,209.4,101.8,209.1,101.8z"></path>
            <path d="M222.4,115.9l-3.1-6.9c0-0.1-0.1-0.1-0.2-0.1h-2.8c-0.1,0-0.1,0-0.1,0.1v6.7c0,0.3-0.1,0.4-0.4,0.4h-2
            c-0.3,0-0.4-0.1-0.4-0.4V99.6c0-0.3,0.1-0.4,0.4-0.4h6.5c1,0,1.8,0.2,2.6,0.6s1.3,1,1.8,1.7c0.4,0.7,0.6,1.6,0.6,2.6
            c0,1.1-0.3,2-0.9,2.8s-1.3,1.3-2.3,1.6c0,0-0.1,0-0.1,0.1s0,0.1,0,0.1l3.3,6.9v0.2c0,0.2-0.1,0.3-0.3,0.3h-2.1
            C222.6,116.2,222.4,116.1,222.4,115.9z M216.1,101.9v4.7c0,0.1,0,0.1,0.1,0.1h3.6c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.8
            c0-0.8-0.2-1.4-0.7-1.8c-0.5-0.5-1.1-0.7-1.9-0.7h-3.6C216.1,101.8,216.1,101.8,216.1,101.9z"></path>
            <path d="M234.7,115.8v-16c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v16c0,0.3-0.1,0.4-0.4,0.4h-2
            C234.9,116.2,234.7,116.1,234.7,115.8z"></path>
            <path d="M242,115.8v-16c0-0.3,0.1-0.4,0.4-0.4h1.9c0.2,0,0.4,0.1,0.5,0.3l6.7,11c0,0.1,0.1,0.1,0.1,0.1l0.1-0.1V99.9
            c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v16c0,0.3-0.1,0.4-0.4,0.4h-2c-0.2,0-0.4-0.1-0.5-0.3l-6.7-11.1
            c0-0.1-0.1-0.1-0.1-0.1l-0.1,0.1v10.9c0,0.3-0.1,0.4-0.4,0.4h-2C242.1,116.2,242,116.1,242,115.8z"></path>
            <path
                d="M270.2,99.8v1.7c0,0.3-0.1,0.4-0.4,0.4h-4.3c-0.1,0-0.1,0-0.1,0.1v13.8c0,0.3-0.1,0.4-0.4,0.4h-2c-0.3,0-0.4-0.1-0.4-0.4
            V102c0-0.1,0-0.1-0.1-0.1h-4.1c-0.3,0-0.4-0.1-0.4-0.4v-1.7c0-0.3,0.1-0.4,0.4-0.4h11.5C270.1,99.4,270.2,99.5,270.2,99.8z">
            </path>
            <path d="M284.7,101.8h-8.1c-0.1,0-0.1,0-0.1,0.1v4.4c0,0.1,0,0.1,0.1,0.1h5.4c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4
            h-5.4c-0.1,0-0.1,0-0.1,0.1v4.6c0,0.1,0,0.1,0.1,0.1h8.1c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4h-10.6
            c-0.3,0-0.4-0.1-0.4-0.4v-16c0-0.3,0.1-0.4,0.4-0.4h10.6c0.3,0,0.4,0.1,0.4,0.4v1.6C285.1,101.7,285,101.8,284.7,101.8z">
            </path>
            <path d="M288.9,115.8v-16c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v13.9c0,0.1,0,0.1,0.1,0.1h7.9c0.3,0,0.4,0.1,0.4,0.4v1.6
            c0,0.3-0.1,0.4-0.4,0.4h-10.5C289,116.2,288.9,116.1,288.9,115.8z"></path>
            <path d="M303.6,115.8v-16c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v13.9c0,0.1,0,0.1,0.1,0.1h7.9c0.3,0,0.4,0.1,0.4,0.4v1.6
            c0,0.3-0.1,0.4-0.4,0.4h-10.5C303.7,116.2,303.6,116.1,303.6,115.8z"></path>
            <path d="M318.2,115.8v-16c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v16c0,0.3-0.1,0.4-0.4,0.4h-2
            C318.4,116.2,318.2,116.1,318.2,115.8z"></path>
            <path d="M327.9,115.7c-0.9-0.5-1.6-1.1-2.1-2s-0.7-1.9-0.7-3v-5.8c0-1.1,0.2-2.1,0.7-3s1.2-1.5,2.1-2s2-0.7,3.2-0.7
            s2.2,0.2,3.1,0.7s1.6,1.1,2.1,1.9c0.5,0.8,0.7,1.7,0.7,2.7v0.1c0,0.3-0.1,0.4-0.4,0.4h-2c-0.1,0-0.2,0-0.3-0.1
            c-0.1,0-0.1-0.1-0.1-0.2v-0.2c0-0.9-0.3-1.6-0.9-2.1c-0.6-0.6-1.4-0.8-2.3-0.8c-1,0-1.8,0.3-2.4,0.9s-0.9,1.4-0.9,2.3v6
            c0,1,0.3,1.7,0.9,2.3s1.4,0.9,2.4,0.9s1.7-0.3,2.3-0.8s0.8-1.2,0.8-2.1v-1.5c0-0.1,0-0.1-0.1-0.1h-2.8c-0.3,0-0.4-0.1-0.4-0.4v-1.6
            c0-0.3,0.1-0.4,0.4-0.4h5.4c0.3,0,0.4,0.1,0.4,0.4v3c0,1.8-0.5,3.3-1.6,4.3s-2.5,1.5-4.4,1.5C329.9,116.4,328.8,116.2,327.9,115.7z
            "></path>
            <path d="M352,101.8h-8.1c-0.1,0-0.1,0-0.1,0.1v4.4c0,0.1,0,0.1,0.1,0.1h5.4c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4h-5.4
            c-0.1,0-0.1,0-0.1,0.1v4.6c0,0.1,0,0.1,0.1,0.1h8.1c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4h-10.6c-0.3,0-0.4-0.1-0.4-0.4
            v-16c0-0.3,0.1-0.4,0.4-0.4H352c0.3,0,0.4,0.1,0.4,0.4v1.6C352.4,101.7,352.2,101.8,352,101.8z"></path>
            <path d="M356.2,115.8v-16c0-0.3,0.1-0.4,0.4-0.4h1.9c0.2,0,0.4,0.1,0.5,0.3l6.7,11c0,0.1,0.1,0.1,0.1,0.1l0.1-0.1V99.9
            c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v16c0,0.3-0.1,0.4-0.4,0.4h-2c-0.2,0-0.4-0.1-0.5-0.3l-6.7-11.1
            c0-0.1-0.1-0.1-0.1-0.1l-0.1,0.1v10.9c0,0.3-0.1,0.4-0.4,0.4h-2C356.3,116.2,356.2,116.1,356.2,115.8z"></path>
            <path
                d="M375.5,115.7c-0.9-0.5-1.6-1.1-2.1-2c-0.5-0.8-0.7-1.8-0.7-3v-5.9c0-1.1,0.2-2.1,0.7-3c0.5-0.8,1.2-1.5,2.1-2
            s2-0.7,3.2-0.7s2.2,0.2,3.1,0.7c0.9,0.4,1.6,1.1,2.1,1.9c0.5,0.8,0.7,1.8,0.7,2.8c0,0.2-0.1,0.3-0.4,0.3l-2,0.1h-0.1
            c-0.2,0-0.3,0-0.3-0.1v-0.1c0-0.9-0.3-1.7-0.9-2.3s-1.4-0.9-2.3-0.9c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.3-0.9,2.3v6.1
            c0,0.9,0.3,1.7,0.9,2.3s1.4,0.9,2.4,0.9s1.8-0.3,2.3-0.9c0.6-0.6,0.9-1.3,0.9-2.3c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1
            l2,0.1c0.3,0,0.4,0.1,0.4,0.1v0.1c0,1.1-0.2,2-0.7,2.9s-1.2,1.5-2.1,1.9s-2,0.7-3.1,0.7C377.5,116.4,376.4,116.2,375.5,115.7z">
            </path>
            <path d="M399.6,101.8h-8.1c-0.1,0-0.1,0-0.1,0.1v4.4c0,0.1,0,0.1,0.1,0.1h5.4c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4
            h-5.4c-0.1,0-0.1,0-0.1,0.1v4.6c0,0.1,0,0.1,0.1,0.1h8.1c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4H389
            c-0.3,0-0.4-0.1-0.4-0.4v-16c0-0.3,0.1-0.4,0.4-0.4h10.6c0.3,0,0.4,0.1,0.4,0.4v1.6C400,101.7,399.8,101.8,399.6,101.8z">
            </path>
            <path d="M423,116c0,0.1,0,0.1-0.1,0.2c-0.1,0-0.1,0.1-0.2,0.1h-2.3c-0.2,0-0.4-0.1-0.5-0.2l-1-1.1c-0.1-0.1-0.1-0.1-0.2,0
            c-1.5,1-3.1,1.6-4.8,1.6c-1.5,0-2.7-0.3-3.6-1s-1.4-1.7-1.4-3.1c0-1.1,0.3-2,0.9-2.7s1.4-1.4,2.4-2.1c0.1,0,0.1-0.1,0-0.2
            c-0.8-1-1.4-1.7-1.7-2.3c-0.3-0.5-0.4-1-0.4-1.6c0-1.3,0.4-2.3,1.3-3c0.8-0.8,1.9-1.2,3.3-1.2c0.9,0,1.8,0.2,2.5,0.5
            s1.3,0.8,1.7,1.5s0.6,1.3,0.6,2.2c0,1-0.3,1.8-0.9,2.5s-1.4,1.3-2.4,2c-0.1,0-0.1,0.1,0,0.2l1.4,1.6l1.3,1.5h0.1h0.1
            c0.5-0.5,0.9-1.1,1.4-1.8c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.2,0.1l1.5,0.8c0.1,0.1,0.2,0.2,0.2,0.3s0,0.2-0.1,0.2
            c-0.6,0.9-1.3,1.8-1.9,2.5v0.1c0,0,0,0.1,0.1,0.2l1.3,1.5l0.8,0.9C422.9,115.8,423,115.9,423,116z M417,113c0.1-0.1,0.1-0.1,0-0.2
            c-0.3-0.4-0.6-0.7-0.7-0.8c-0.2-0.3-0.5-0.6-0.8-0.9l-1.6-1.8c-0.1-0.1-0.1-0.1-0.2,0c-0.7,0.4-1.2,0.9-1.5,1.3
            c-0.4,0.5-0.6,1-0.6,1.5c0,0.6,0.2,1,0.6,1.4c0.4,0.3,1,0.5,1.6,0.5C414.9,114,415.9,113.7,417,113z M413.4,102.2
            c-0.3,0.3-0.5,0.7-0.5,1.2c0,0.3,0.1,0.6,0.3,0.9s0.6,0.9,1.2,1.6c0.1,0.1,0.2,0.1,0.2,0c0.7-0.4,1.2-0.9,1.5-1.3
            c0.4-0.4,0.5-0.8,0.5-1.3s-0.2-0.9-0.5-1.2c-0.4-0.3-0.8-0.4-1.4-0.4C414.2,101.7,413.7,101.9,413.4,102.2z"></path>
            <path d="M435,115.8c-0.9-0.4-1.6-1-2.1-1.7s-0.7-1.6-0.7-2.5v-0.5c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v0.4
            c0,0.7,0.3,1.3,1,1.8c0.6,0.5,1.5,0.7,2.6,0.7c1,0,1.7-0.2,2.2-0.6c0.5-0.4,0.7-1,0.7-1.6c0-0.4-0.1-0.8-0.4-1.1s-0.7-0.6-1.2-0.9
            s-1.3-0.6-2.3-1c-1.1-0.4-2.1-0.8-2.8-1.2c-0.7-0.4-1.3-0.9-1.8-1.5s-0.7-1.4-0.7-2.4c0-1.4,0.5-2.5,1.5-3.3s2.4-1.2,4.1-1.2
            c1.2,0,2.3,0.2,3.2,0.6c0.9,0.4,1.6,1,2.1,1.7s0.8,1.6,0.8,2.6v0.3c0,0.3-0.1,0.4-0.4,0.4h-2c-0.1,0-0.2,0-0.3-0.1
            c-0.1-0.1-0.1-0.1-0.1-0.2v-0.3c0-0.8-0.3-1.4-0.9-1.9s-1.5-0.8-2.6-0.8c-0.9,0-1.6,0.2-2.1,0.6s-0.7,0.9-0.7,1.6
            c0,0.5,0.1,0.9,0.4,1.2s0.6,0.6,1.2,0.8c0.5,0.2,1.3,0.6,2.4,0.9c1.1,0.4,2,0.8,2.7,1.2s1.3,0.8,1.8,1.5s0.7,1.4,0.7,2.4
            c0,0.9-0.2,1.7-0.7,2.5c-0.5,0.7-1.2,1.3-2.1,1.7s-2,0.6-3.2,0.6C437,116.4,435.9,116.2,435,115.8z"></path>
            <path
                d="M458.9,101.8h-8.1c-0.1,0-0.1,0-0.1,0.1v4.4c0,0.1,0,0.1,0.1,0.1h5.4c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4
            h-5.4c-0.1,0-0.1,0-0.1,0.1v4.6c0,0.1,0,0.1,0.1,0.1h8.1c0.3,0,0.4,0.1,0.4,0.4v1.6c0,0.3-0.1,0.4-0.4,0.4h-10.6
            c-0.3,0-0.4-0.1-0.4-0.4v-16c0-0.3,0.1-0.4,0.4-0.4h10.6c0.3,0,0.4,0.1,0.4,0.4v1.6C459.3,101.7,459.2,101.8,458.9,101.8z">
            </path>
            <path
                d="M465.5,115.7c-0.9-0.5-1.6-1.1-2.1-2c-0.5-0.8-0.7-1.8-0.7-3v-5.9c0-1.1,0.2-2.1,0.7-3c0.5-0.8,1.2-1.5,2.1-2
            s2-0.7,3.2-0.7s2.2,0.2,3.1,0.7c0.9,0.4,1.6,1.1,2.1,1.9c0.5,0.8,0.7,1.8,0.7,2.8c0,0.2-0.1,0.3-0.4,0.3l-2,0.1h-0.1
            c-0.2,0-0.3,0-0.3-0.1v-0.1c0-0.9-0.3-1.7-0.9-2.3s-1.4-0.9-2.3-0.9c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.3-0.9,2.3v6.1
            c0,0.9,0.3,1.7,0.9,2.3s1.4,0.9,2.4,0.9s1.8-0.3,2.3-0.9c0.6-0.6,0.9-1.3,0.9-2.3c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.2-0.1,0.3-0.1
            l2,0.1c0.3,0,0.4,0.1,0.4,0.1v0.1c0,1.1-0.2,2-0.7,2.9s-1.2,1.5-2.1,1.9s-2,0.7-3.1,0.7C467.5,116.4,466.4,116.2,465.5,115.7z">
            </path>
            <path d="M481.4,115.7c-0.9-0.5-1.6-1.1-2.1-2s-0.8-1.8-0.8-3v-11c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v11
            c0,0.9,0.3,1.7,0.9,2.3c0.6,0.6,1.4,0.9,2.4,0.9s1.8-0.3,2.4-0.9c0.6-0.6,0.9-1.4,0.9-2.3v-11c0-0.3,0.1-0.4,0.4-0.4h2
            c0.3,0,0.4,0.1,0.4,0.4v11c0,1.1-0.2,2.1-0.7,3s-1.2,1.5-2.1,2s-2,0.7-3.2,0.7C483.3,116.4,482.3,116.2,481.4,115.7z">
            </path>
            <path d="M503.9,115.9l-3.1-6.9c0-0.1-0.1-0.1-0.2-0.1h-2.8c-0.1,0-0.1,0-0.1,0.1v6.7c0,0.3-0.1,0.4-0.4,0.4h-2
            c-0.3,0-0.4-0.1-0.4-0.4V99.6c0-0.3,0.1-0.4,0.4-0.4h6.5c1,0,1.8,0.2,2.6,0.6c0.8,0.4,1.3,1,1.8,1.7c0.4,0.7,0.6,1.6,0.6,2.6
            c0,1.1-0.3,2-0.9,2.8c-0.6,0.8-1.3,1.3-2.3,1.6c0,0-0.1,0-0.1,0.1v0.1l3.3,6.9v0.2c0,0.2-0.1,0.3-0.3,0.3h-2.1
            C504.1,116.2,504,116.1,503.9,115.9z M497.6,101.9v4.7c0,0.1,0,0.1,0.1,0.1h3.6c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.8
            c0-0.8-0.2-1.4-0.7-1.8c-0.5-0.5-1.1-0.7-1.9-0.7h-3.6C497.7,101.8,497.6,101.8,497.6,101.9z"></path>
            <path d="M510.5,115.8v-16c0-0.3,0.1-0.4,0.4-0.4h2c0.3,0,0.4,0.1,0.4,0.4v16c0,0.3-0.1,0.4-0.4,0.4h-2
            C510.6,116.2,510.5,116.1,510.5,115.8z"></path>
            <path
                d="M529.1,99.8v1.7c0,0.3-0.1,0.4-0.4,0.4h-4.3c-0.1,0-0.1,0-0.1,0.1v13.8c0,0.3-0.1,0.4-0.4,0.4h-2c-0.3,0-0.4-0.1-0.4-0.4
            V102c0-0.1,0-0.1-0.1-0.1h-4.1c-0.3,0-0.4-0.1-0.4-0.4v-1.7c0-0.3,0.1-0.4,0.4-0.4h11.5C529,99.4,529.1,99.5,529.1,99.8z">
            </path>
            <path d="M536.7,115.8v-6.3c0,0,0-0.1,0-0.2l-4.8-9.5c0-0.1-0.1-0.2-0.1-0.2c0-0.2,0.1-0.2,0.3-0.2h2.2c0.2,0,0.4,0.1,0.5,0.3
            l3.3,6.6l0.1,0.1c0,0,0.1,0,0.1-0.1l3.3-6.6c0.1-0.2,0.2-0.3,0.5-0.3h2.2c0.2,0,0.3,0.1,0.3,0.2c0,0.1,0,0.1-0.1,0.2l-4.9,9.5
            c0,0.1,0,0.2,0,0.2v6.3c0,0.3-0.1,0.4-0.4,0.4h-2C536.8,116.2,536.7,116,536.7,115.8z"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'DtexLogo',
        props: {
            height: {
                type: Number,
                required: false,
                default: null
            },
            isDarkTheme: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        components: {
        },
        setup() {
        }
    }
</script>

<style>

</style>