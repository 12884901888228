<template>
  <p class="py-2 stealth-gradient logo-text text-center" :style="{ 'font-size': 'max(min(' + maxFontSize + 'px, ' + (unlimitedSize ? '100' : '3.5') + 'vw), ' + minFontSize + 'px)', '--initial-gradient-color': hexColor, '--final-gradient-color': hexBottomColor }"><strong>{{ logoText }}</strong></p>
</template>

<script>
// import { ref } from 'vue'
export default {
        name: 'GradientLogo',
        props: {
            logoText: {
                type: String,
                required: true
            },
            maxFontSize: {
                type: Number,
                required: false,
                default: 48
            },
            minFontSize: {
                type: Number,
                required: false,
                default: 16
            },
            unlimitedSize: {
                type: Boolean,
                required: false,
                default: true
            },
            hexColor: {
                type: String,
                required: false,
                default: '#dbdbdb'
            },
            hexBottomColor: {
                type: String,
                required: false,
                default: '#333'
            }
        },
        components: {
        },
        setup() {
            
        }
    }
</script>

<style lang="scss" scoped>

.logo-text {
     font-family: "Sleigher-Laser-Italic";
     padding-right: 0.2em;
     margin-right: 0.2em;
     cursor: pointer;
  }

.stealth-gradient {
    background: -webkit-linear-gradient(var(--initial-gradient-color), var(--final-gradient-color));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // Print properly from browser
    -webkit-print-color-adjust: exact;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact;                 /* Firefox 48 – 96 */
    print-color-adjust: exact;  
}

</style>