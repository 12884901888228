<template>
  <p class="pickup-color footer-menu-title text-center" :style="{ 'font-size': 'max(min(' + maxFontSize + 'px, ' + (unlimitedSize ? '100' : '3.5') + 'vw), ' + minFontSize + 'px)' }"><strong>{{ abbreviated ? logoTextAbbreviated : logoText }}</strong></p>
</template>

<script>
import { ref } from 'vue'
export default {
        name: 'MHPSLogo',
        props: {
            abbreviated: {
                type: Boolean,
                required: false,
                default: false
            },
            maxFontSize: {
                type: Number,
                required: false,
                default: 48
            },
            minFontSize: {
                type: Number,
                required: false,
                default: 28
            },
            unlimitedSize: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        components: {
        },
        setup() {
            const logoTextAbbreviated = ref('MHPS')
            const logoText = ref(`Mike's High Performance Software`)

            return {
                logoText,
                logoTextAbbreviated
            }
        }
    }
</script>

<style lang="scss" scoped>

.footer-menu-title {
     font-family: "Sleigher-Laser-Italic";
     cursor: pointer;
  }

</style>