<template>
    <v-container :class="['bg-transparent', verticalOverflow ? 'overflow-y-auto' : undefined]"
        :style="{ 'height': height + 'px', 'min-height': height + 'px', 'max-height': height + 'px', 'width': width ? width + 'px': '100%' }"
        fluid>
        <v-row wrap dense justify="center" :style="{ 'height': '100%', 'opacity': 1.0 }">
            <v-col cols="12" xs="12" sm="12" :md="width ? 12 : 9" :lg="width ? 12 : 9" :xl="width ? 12 : 9"
                class="d-flex flex-column justify-center align-center">
                <v-card elevation="12" class="mx-auto">
                    <v-img :src="showBannerImage ? require('@/assets/images/mhps-logo-1956-chevy-pickup.jpg') : undefined" :height="showBannerImage ? '160px' : '135px'" cover>
                        <v-avatar variant="outlined" size="120"
                            :style="{ position: 'absolute', top: '50%', left: '75px', transform: 'translate(-50%, -50%)'}">
                            <v-img :src="require('@/assets/images/mjc-avatar.png')" :alt="resumeData.name"></v-img>
                        </v-avatar>
                    </v-img>

                    <v-card-title :class="[uniformFont ? undefined : 'sleigher-font', uniformFont ? undefined : 'pickup-color', 'mt-1', 'pt-0']">
                        {{ resumeData.name }}
                    </v-card-title>

                    <v-card-text :class="[uniformFont ? undefined : 'text-medium-emphasis', 'mt-1', 'pt-0', 'mb-0', 'pb-0']">
                        {{ resumeData.summary }}
                    </v-card-text>

                    <v-container class="mt-1 pt-0" :style="{ 'width': width ? '100%' : undefined, 'max-width': width ? '100%' : undefined }">
                        <v-row v-if="showContactInfo">
                            <v-col>
                                <v-card-text>
                                    <div class="mb-1 font-weight-bold text-uppercase">Contact</div>
                                    <ul class="ml-1">
                                        <li style="list-style-type: none; min-width: 400px;" v-for="contact in resumeData.contacts" :key="contact"><v-icon class="mr-1" :icon="contact.logo"></v-icon>{{ contact.text }}</li>
                                    </ul>
                                </v-card-text>
                            </v-col>
                        </v-row>
                        <v-row :class="mdAndUp ? undefined : 'flex-column'">
                            <v-col :cols="mdAndUp ? '4' : undefined">
                                <v-card-text>
                                    <div class="mb-1 font-weight-bold text-uppercase">Focus</div>
                                    <ul class="ml-3">
                                        <li v-for="skill in resumeData.skills" :key="skill">{{ skill }}</li>
                                    </ul>
                                </v-card-text>

                                <v-card-text>
                                    <div class="mb-1 font-weight-bold text-uppercase">Platforms</div>
                                    <v-chip v-for="platform in resumeData.platforms" :key="platform" variant="outlined"
                                        class="clickable" @click="visitExternalLink(platform.link)">
                                        <v-icon v-if="platform.logo" start :icon="platform.logo"></v-icon>
                                        <div v-if="platform.name">{{ platform.name }}</div>
                                    </v-chip>
                                </v-card-text>

                                <v-card-text>
                                    <div class="mb-1 font-weight-bold text-uppercase">Tools</div>
                                    <v-chip v-for="tool in resumeData.tools" :key="tool" variant="outlined"
                                        class="clickable" @click="visitExternalLink(tool.link)"
                                        :style="{ 'background': (tool.light ? 'white' : undefined) }"
                                        >
                                        <v-icon v-if="tool.logo" start :icon="tool.logo"></v-icon>
                                        <v-img v-if="tool.url || tool.urlDark" start
                                            :src="((!isDarkTheme && tool.urlDark) ? tool.urlDark : tool.url)"
                                            :class="(tool.name ? 'mr-2' : undefined)"
                                            :style="{ 'margin-left': -6 + 'px' }" :width="(tool.name ? 20 : 100)" max-height="20"></v-img>
                                        <div v-if="tool.name">{{ tool.name }}</div>
                                    </v-chip>
                                </v-card-text>

                                <v-card-text>
                                    <div class="mb-3 font-weight-bold text-uppercase">Education</div>
                                    <div class="ma-0 pa-0 d-flex justify-center align-center">
                                        <v-card v-for="(school, i) in resumeData.education" :key="i" elevation="12"
                                            class="ma-0 pa-0" :style="{ 'width': mdAndUp ? '285px' : '75vw' }">
                                            <div v-if="school.organization === 'Trinity College'"
                                                class="pt-4 pb-2 d-flex justify-center align-center">
                                                <TrinityLogo :height="35" :isDarkTheme="isDarkTheme" class="clickable"
                                                    @click="visitExternalLink(school.url)">
                                                </TrinityLogo>
                                            </div>
                                            <v-img class="ml-4 mt-4 mb-2 clickable" v-if="school.logo"
                                                :src="school.logo" height="50" @click="visitExternalLink(school.url)">
                                            </v-img>
                                            <div class="d-flex flex-column align-center justify-start">
                                                <v-card-text :class="[uniformFont ? undefined : 'text-medium-emphasis', 'mb-0', 'pb-0']">
                                                    {{ school.degree }}
                                                </v-card-text>
                                                <v-card-text class="white">
                                                    <ul class="ml-3">
                                                        <li v-for="(distinction, i) in school.distinctions" :key="i">{{
                                                        distinction }}</li>
                                                    </ul>
                                                </v-card-text>
                                            </div>
                                        </v-card>
                                    </div>
                                </v-card-text>
                            </v-col>
                            <v-col :cols="mdAndUp ? '8' : undefined">
                                <v-card-text>
                                    <div class="mb-1 font-weight-bold text-uppercase">Experience</div>
                                    <template v-if="experienceTimeline">
                                        <v-timeline align="start" side="end" size="small">
                                        <v-timeline-item v-for="(job, i) in resumeData.experience" :key="i" fill-dot
                                            :size="10">
                                            <!-- :dot-color="--pickup-color" :icon="item.icon" -->
                                            <template v-slot:opposite>
                                                <div :class="'pt-1 headline font-weight-bold'">
                                                    {{ job.end ? `${job.start} - ${job.end}` : `Since ${job.start}` }}
                                                </div>
                                            </template>
                                            <ExperienceItem :job="job" :uniformFont="uniformFont" :isDarkTheme="isDarkTheme"></ExperienceItem>
                                        </v-timeline-item>
                                    </v-timeline>
                                    </template>
                                    <v-container v-else class="ma-0 pa-0">
                                        <v-row class="ma-0 pa-0" v-for="(job, i) in resumeData.experience" :key="i">
                                            <v-col class="ml-0 mr-0 pl-0 pr-0">
                                                <ExperienceItem :job="job" :showDates="true" :uniformFont="uniformFont" :showURLs="true" :isDarkTheme="isDarkTheme"></ExperienceItem>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card-actions>

                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { reactive } from 'vue'
import { useDisplay } from 'vuetify'
import TrinityLogo from './logos/TrinityLogo.vue'
import ExperienceItem from './resumeItems/ExperienceItem.vue'
export default {
name: 'Resume',
props: {
    height: {
        type: Number,
        required: false,
        default: null
    },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
        type: Number,
        required: false,
        default: null
    },
        verticalOverflow: {
            type: Boolean,
            required: false,
            default: true
        }
        ,
        experienceTimeline: {
            type: Boolean,
            required: false,
            default: true
        },
        showBannerImage: {
            type: Boolean,
            required: false,
            default: true
        },
        showContactInfo: {
            type: Boolean,
            required: false,
            default: false
        },
        uniformFont: {
            type: Boolean,
            required: false,
            default: false
        }
},
components: {
    TrinityLogo, ExperienceItem
},
setup(props) {
    const resumeData = reactive({
        name: 'Michael Cross',
        summary: 'Full stack software engineer with professional experience developing for SaaS/Cloud, desktop, mobile on projects in cybersecurity, mobile payments and home automation since 2006.',
        skills: [
            'Website & Cloud Application Architecture',
            'Cloud Infrastructure / DevSecOps',
            'Native Desktop & Mobile Applications',
            'Research, Proposals, Designs, Prototypes, Proofs of Concept',
            'Debugging, Testing, Profiling, Performance',
            'Agile Development Methodologies & Tools, Process Efficiency',
            'Documentation',
            'Training',
            'Technical Leadership',
        ]
        ,
        contacts: [
            { logo: 'mdi-email', text: 'mikes.high.performance.software@gmail.com'},
            { logo: 'mdi-web', text: 'www.mjchp.com' },
            { logo: 'mdi-github', text: 'https://github.com/ir0nCr055' },
            { logo: 'mdi-linkedin', text: 'https://www.linkedin.com/in/michael-cross-2aaa8527' },
            { logo: 'mdi-stack-overflow', text: 'https://stackoverflow.com/users/2333933/mike-c' }
        ],
        platforms: [
            { name: 'macOS', logo: 'mdi-apple', link: 'https://www.apple.com/macos' },
            { name: 'iOS', logo: 'mdi-apple', link: 'https://www.apple.com/ios' }, 
            { name: 'iPadOS', logo: 'mdi-apple', link: 'https://www.apple.com/ipados' },
            { name: 'Linux', logo: 'mdi-linux', link: 'https://rockylinux.org/' }, 
            { name: 'Amazon Web Services', logo: 'mdi-aws', link: 'https://aws.amazon.com/' },
            { name: 'Google Cloud Platform', logo: 'mdi-google-cloud', link: 'https://cloud.google.com/' },
            { name: 'DigitalOcean', logo: 'mdi-digital-ocean', link: 'https://m.do.co/c/c08342d49456' }
        ],
        tools: [
            { name: 'Javascript', logo: 'mdi-language-javascript', link: 'https://en.wikipedia.org/wiki/JavaScript' },
            { name: 'Swift', logo: 'mdi-language-swift', link: 'https://www.swift.org/' }, 
            { name: 'Obj-C', logo: 'mdi-apple', link: 'https://developer.apple.com/library/archive/documentation/Cocoa/Conceptual/ProgrammingWithObjectiveC/Introduction/Introduction.html' },
            { name: 'C', logo: '', url: 'https://upload.wikimedia.org/wikipedia/commons/1/18/C_Programming_Language.svg', link: 'https://en.wikipedia.org/wiki/C_(programming_language)' }, 
            { name: 'Docker', logo: 'mdi-docker', link: 'https://www.docker.com/' },
            { name: 'PostgreSQL', logo: '', url: 'https://wiki.postgresql.org/images/a/a4/PostgreSQL_logo.3colors.svg', link: 'https://www.postgresql.org/' },
            { name: '', logo: '', url: require('@/assets/images/logos/arangodb-logo-light.png'), urlDark: 'https://www.arangodb.com/wp-content/uploads/2022/06/ArangoDB-dark-logo-2022.png', link: 'https://www.arangodb.com/' },
            { name: '', logo: '', url: require('@/assets/images/logos/auth0-logo-primary-light.svg'), urlDark: require('@/assets/images/logos/auth0-logo-primary-dark.svg'), link: 'https://auth0.com/'},
            { name: '', logo: '', url: require('@/assets/images/logos/openai-logo-light.svg'), urlDark: require('@/assets/images/logos/openai-logo-dark.svg'), link: 'https://openai.com/'},
            { name: 'Anthropic AI', logo: '', url: require('@/assets/images/logos/anthropic.svg'), urlDark: require('@/assets/images/logos/anthropic-dark.svg'), link: 'https://www.anthropic.com'},
            { name: 'bash', logo: 'mdi-bash', link: 'https://www.gnu.org/software/bash/' },
            { name: 'GraphQL', logo: 'mdi-graphql', link: 'https://graphql.org/' }, 
            { name: 'Git', logo: 'mdi-git', link: 'https://git-scm.com/' },
            { name: 'HTML', logo: 'mdi-language-html5', link: 'https://html.spec.whatwg.org/' }, 
            { name: 'CSS', logo: 'mdi-language-css3', link: 'https://www.w3.org/TR/CSS/#css' },
            { name: 'Node.js', logo: 'mdi-nodejs', link: 'https://nodejs.org/en/' },
            { name: 'Vue.js', logo: 'mdi-vuejs', link: 'https://vuejs.org/' },
            { name: 'React', logo: '', url: require('@/assets/images/logos/react.svg'), link: 'https://react.dev/' },
            { name: 'Vuetify', logo: 'mdi-vuetify', link: 'https://vuetifyjs.com' },
            { name: '', logo: '', url: require('@/assets/images/logos/hasura-light.svg'), urlDark: require('@/assets/images/logos/hasura-dark.svg'), link: 'https://hasura.io/' },
            { name: 'Nginx', logo: '', url: require('@/assets/images/logos/nginx.png'), link: 'https://www.nginx.com/' }, 
            { name: 'Jenkins', logo: '', url: require('@/assets/images/logos/jenkins.png'), link: 'https://www.jenkins.io/' },
            { name: 'Github', logo: 'mdi-github', link: 'https://github.com/' },
            { name: 'XCode', logo: 'mdi-apple', link: 'https://developer.apple.com/xcode/'},
            { name: 'VSCode', logo: 'mdi-microsoft-visual-studio-code', link: 'https://code.visualstudio.com/'},
            { name: 'ClickUp', logo: '', url: require('@/assets/images/logos/clickup.png'), link: 'https://clickup.com/' },
            { name: 'YouTrack', logo: '', url: require('@/assets/images/logos/youtrack.svg'), link: 'https://www.jetbrains.com/youtrack/' },
            { name: 'Jira', logo: 'mdi-jira', link: 'https://www.atlassian.com/software/jira' },
            { name: 'CloudConnexa', logo: '', url: require('@/assets/images/logos/OpenVPN-dark.svg'), urlDark: require('@/assets/images/logos/OpenVPN.svg'), link: 'https://openvpn.net/cloud-vpn/' },
            { name: '', url: require('@/assets/images/logos/cesiumjs_white.svg'), urlDark: require('@/assets/images/logos/cesiumjs_black.svg'), link: 'https://cesium.com/platform/cesiumjs/' }
        ],
        experience: [
        {
                organization: 'Stealth Cybersecurity Startup',
                orgDescription: 'A new startup venture looking to improve enterprise cybersecurity posture',
                // url: '',
                start: 'Mar. \'22',
                end: null,
                title: 'Sr. Software Engineer, Tech Lead (Contract)',
                distinctions: [
                    'Working closely with the founders to launch a new generative AI powered SaaS product in the cybersecurity space',
                    'Serving as a full stack developer and technical lead focused on product architecture, development, CI/CD infrastructure and DevOps',
                ]
            },
            {
                organization: 'Revelstoke Security (Acquired by Arctic Wolf)',
                orgDescription: 'Revelstoke Security offered a new low-code/no-code security orchestration, automation and response platform for enterprise security teams before it was aquired by Arctic Wolf in 2023',
                url: 'https://www.revelstoke.io',
                logo: props.isDarkTheme ? require('@/assets/images/logos/arctic-wolf-and-revelstoke-light.png') : require('@/assets/images/logos/arctic-wolf-and-revelstoke-dark.png'),
                start: 'Sept. \'20',
                end: 'June \'24',
                title: 'Sr. Software Engineer / Sr. Software Engineer (Contract)',
                distinctions: [
                    'Designed and implemented the security workflow/playbook runtime for company\'s next-gen S.O.A.R. product including major iterations for performance, efficiency, and scalability',
                    'Lead development on a docker-compose based deployment project to standardize the full stack development environment',
                    'Lead development adopting product\'s first identity provider: Keycloak',
                    'Developed key 3rd party integrations: Slack messaging prototype, IMAP/email processing, Snowflake',
                    '\'Founding Team\' T-shirt owner and key contributor to early VC product demos and customer proofs-of-concept'
                ]
            },
            {
                organization: 'Mike\'s High Performance Software',
                orgDescription: `Mike's High Performance Software is a personal brand for self-published software and consulting services`,
                url: 'https://www.mjchp.com',
                start: 'Oct. \'11',
                end: null,
                title: 'Sole Proprietor',
                distinctions: [
                    'Developed and deployed \'trak.watch\', a near-realtime, 3D, photo-realistic Amtrak ride-a-long experience',
                    'Developed and deployed \'www.itrackbro.com\', a shipped package tracker cloud app (SPA) with rich progress details including a route map visualization',
                    'Developed and deployed \'www.essentialscaryterry.com\', an (unofficial) soundboard website (SPA) for a popular character from a Cartoon Network series',
                    'Developed and released \'iScoredBro\', a score-keeping application with data-logging and export functionality for iOS and macOS (available in Apple\'s iOS, macOS App Stores)',
                    'Developed and released \'iTalkBro\', a voice recorder and meme generator for iOS (available in Apple\'s iOS App Store)',
                    'For more information about the above projects check out the MHPS website: \'https://www.mjchp.com/\''
                ]
            },
            {
                organization: 'Dtex Systems',
                orgDescription: 'Dtex Systems offers an enterprise-scale workforce cyber-intelligence and security platform',
                url: 'https://www.dtexsystems.com',
                start: 'Feb. \'15',
                end: 'Sept. \'20',
                title: 'Sr. Software Engineer',
                distinctions: [
                    'Primary macOS and Linux platform developer for company\'s endpoint security product with wide ranging duties including features, maintenance, documentation and customer support',
                    'Prototyped and productized Linux endpoint software port of existing macOS product to expand revenue opportunity for the business',
                    'Introduced eBPF to the Linux endpoint software to address varied, evolving customer needs'
                ]
            },
            {
                organization: 'Ingenico Mobile Solutions',
                orgDescription: 'Ingenico Mobile Solutions provides mobile electronic payment solutions for large and small businesses worldwide',
                url: 'https://ingenico.us/mobile-solutions',
                logo: props.isDarkTheme ? require('@/assets/images/logos/ingenico_dark.png') : require('@/assets/images/logos/ingenico_light.svg'),
                start: 'Oct. \'13',
                end: 'Feb. \'15',
                title: 'Mobile Application Developer',
                distinctions: [
                    'Enhanced, maintained and supported a portfolio of 10+ mobile point-of-sale applications for the iOS platform',
                    'Development focused on core business logic frameworks, custom branding, internationalization (i18n), localization (l10n), magnetic stripe reader and EMV payment terminal APIs',
                    'Assisted with release engineering, quality assurance, defect tracking',
                    'Provided international API support for 3rd-party developers'
                ]
            },
            {
                organization: 'Savant',
                orgDescription: 'Savant provides premium automation, distributed audio/video, and power management solutions for residential and commercial applications',
                url: 'https://www.savant.com',
                start: 'Feb. \'06',
                end: 'Oct. \'11',
                title: 'Software Engineer',
                distinctions: [
                    'Early hire charged with building out an XML driver library of high-end A/V, HVAC and Security device integrations for the company\'s boutique automation platform',
                    'Quickly promoted to an expanded software engineering role in the company',
                    'Development focused on device specification research / abstraction / XML schema design, event-based XML parsing, control subsystem software for IR, RS232, IP communication, internal tooling / test harnesses, and XML driver-related features in company\'s IDE software product \'Blueprint\'',
                    'Maintained internal and external XML driver documentation; Trained new employees and customer network on XML driver development process',
                    'Interacted closely with customers via remote/on-site customer support, professional services, training courses, and tradeshow appearances (CEDIA, Infocomm)'
                ]
            }
        ],
        education: [
            {
                organization: 'Trinity College',
                url: 'https://www.trincoll.edu',
                degree: 'B.S., Electrical Engineering',
                distinctions: [
                    'Graduated with Honors (3.78 GPA)',
                    'Phi Beta Kappa Honor Society',
                    'Trinity College Scholars Program',
                    'Connecticut Space Grant College Consortium Undergraduate Fellow',
                    'Phi Gamma Delta Prize in Mathematics (First Place)',
                    'Physics Book Award',
                    'Theodore R. Blakeslee II Teaching Assistant Award'
                ]
            }
        ]
    })

    // Window Size Mgmt
    const { mdAndUp, smAndUp, lgAndUp } = useDisplay()
    
    // External Links

    const visitExternalLink = (url) => {
        if (url) {
            window.open(url)
        }
    }

    return {
    resumeData,
    // Functions
    visitExternalLink,
    // Window Size Management
    smAndUp,
    mdAndUp,
    lgAndUp
    }
}
}
</script>

<style>

</style>