<template>
    <div class="bg-transparent pa-0 ma-0">
        <v-btn class="no-print" size="medium" icon="mdi-home-circle-outline" @click="goHome"
            :style="{ position: 'fixed', top: '30px', right: '10px', transform: 'translate(-50%, -50%)', 'z-index': 1000 }"
            ></v-btn>
        <v-theme-provider theme="light">
            <Resume id="printable"
                :height="height"
                :width="width"
                :experienceTimeline="false"
                :showBannerImage="false"
                :showContactInfo="true"
                :verticalOverflow="false"
                :uniformFont="true"
                :isDarkTheme="false" />
        </v-theme-provider>
    </div>
</template>
  
<script>
import { ref } from 'vue'
import { useDisplay } from 'vuetify'
import Resume from './Resume.vue'
// import Resume from './Resume.vue'
// import Vue3Html2pdf from 'vue3-html2pdf'
export default {
  name: 'ResumePrinter',
  head() {
    return {
      // Children can override the title.
      title: `Mike's Resume`,
      // Result: My Page Title ← My Site
      // If a child changes the title to "My Other Page Title",
      // it will become: My Other Page Title ← My Site
      titleTemplate: `MHPS v${process.env.PACKAGE_VERSION} | %s`,
      // Define meta tags here.
      meta: [
        {name: 'description', content: `Mike's Resume`},
        // OpenGraph data (Most widely used)
        {property: 'og:title', content: 'Mike\'s Resume'},
        {property: 'og:site_name', content: 'Mike\'s Resume'},
        // The list of types is available here: http://ogp.me/#types
        {property: 'og:type', content: 'website'},
        // Should the the same as your canonical link, see below.
        {property: 'og:url', content: 'https://www.mjchp.com/resume'},
        {property: 'og:image', content: 'https://www.mjchp.com/favicon.png'},
        // Often the same as your meta description, but not always.
        {property: 'og:description', content: 'Mike\'s Resume'},
        // Google / Schema.org markup:
        {itemprop: 'name', content: `Mike's Resume`},
        {itemprop: 'description', content: `Mike's Resume`},
        {itemprop: 'image', content: 'https://www.mjchp.com/favicon.png'}
      ],
      link: [
        {rel: 'canonical', href: 'https://www.mjchp.com/resume'}
      ]
    }
  },
  props: {
      height: {
          type: Number,
          required: false,
          default: null
      },
      width: {
          type: Number,
          required: false,
          default: null
      },
      homeFunction: {
        type: Function,
        required: false,
        default: () => {}
      }
  },
  components: {
  Resume
  },
  setup(props) {
      const showResumeOverlay = ref(true)

      // Window Size Mgmt
      const { mdAndUp, smAndUp } = useDisplay()

      // Functions
      const goHome = () => {
        props.homeFunction()
      }

      return {
      showResumeOverlay,
      // Functions
      goHome,
      // Window Size Management
      smAndUp,
      mdAndUp
    }
  }
}
</script>
  
<style lang="scss" scoped>
@media print {
    .no-print {
        visibility: hidden;
    }
}
</style>