<template>
  <svg class="icon icon_logo" xmlns="http://www.w3.org/2000/svg" width="1917" height="411" viewBox="0 0 1917 411" :style="{ 'height': height + 'px' }">
    <g :style="{ 'fill': isDarkTheme ? 'white' : 'black'}">
        <path class="cls-1" d="M184.76,5.94H8.36L0,58.18H3.88c0-1.19,25.37-36.71,54.92-36.71h16.72V162.05c0,28.95-9.25,56.12-9.25,56.12v2.69h60.59v-2.09c0-.6-9.55-27.76-9.55-56.71V21.46h16.72c30.45,0,55.22,36.71,55.22,36.71h3.88l-8.36-52.24Z"></path>
        <path class="cls-1" d="M291.62,67.13c-.3,0-.6,.03-.9,.04v-.04c-20.6,0-37.3,10.75-46.86,25.97v-26.27l-46.43,4.78v2.69c5.7,7.58,10.61,24.77,10.61,42.09v63.88c0,22.69-11.34,37.91-11.34,37.91v2.69h58.5v-2.69s-11.34-15.22-11.34-37.91v-48.06c0-22.74,8.56-38.64,23.58-41.11,.04,13.4,10.77,24.1,24.17,24.1s23.88-10.75,23.88-24.18-10.45-23.88-23.88-23.88Z"></path>
        <path class="cls-1" d="M354.69,48.03c12.84,0,23.28-10.45,23.28-23.58s-10.45-23.28-23.28-23.28-23.58,10.45-23.58,23.28,10.45,23.58,23.58,23.58Zm-27.46,172.82h58.5v-2.69s-11.34-15.22-11.34-37.91V66.83l-47.16,4.78v2.69c6.57,5.37,11.34,24.77,11.34,42.09v63.88c0,22.69-11.34,37.91-11.34,37.91v2.69Z"></path>
        <path class="cls-1" d="M602.79,48.03c12.84,0,23.28-10.45,23.28-23.58s-10.45-23.28-23.28-23.28-23.58,10.45-23.58,23.28,10.45,23.58,23.58,23.58Zm-27.46,172.82h58.5v-2.69s-11.34-15.22-11.34-37.91V66.83l-47.16,4.78v2.69c6.57,5.37,11.34,24.77,11.34,42.09v63.88c0,22.69-11.34,37.91-11.34,37.91v2.69Z"></path>
        <path class="cls-1" d="M547.44,180.26v-60c0-31.94-19.7-53.43-48.36-53.43-18.51,0-35.52,11.04-45.37,26.27v-26.27l-47.16,4.78v2.69c6.57,5.37,11.34,24.77,11.34,42.09v63.88c0,22.69-11.34,37.91-11.34,37.91v2.69h56.41v-2.69s-9.25-15.22-9.25-37.91v-48.06c0-24.77,14.03-41.49,31.64-41.49s26.27,12.54,26.27,33.73v55.82c0,22.69-9.55,37.91-9.55,37.91v2.69h54.62v-2.69s-9.25-15.22-9.25-37.91Z"></path>
        <path class="cls-1" d="M844.06,70.94v3.28c5.67,1.49,23.58,9.85,15.82,27.46-4.78,11.04-32.54,73.13-32.54,73.13,0,0-27.16-62.68-32.54-74.62-8.66-20.6,2.09-25.97,2.09-25.97v-3.28h-97.08V12.45h-3.28c-13.43,31.94-26.57,54.92-52.83,66.86v4.18h20.3v97.31c0,19.7,12.24,42.09,38.51,42.09s37.61-11.94,42.68-36.12l-3.88-2.98c-5.07,20.3-41.49,21.19-41.49-7.16V83.48h23.06c22.98,0,31.64,14.63,35.82,23.88l49.55,110.44s-9.25,28.36-59.1,28.36l-2.09,3.28,18.21,34.33c12.84-3.88,38.51-18.21,52.24-47.16,7.46-16.72,56.12-128.05,60.59-138.2,6.87-16.12,27.46-24.18,27.46-24.18v-3.28h-61.49Z"></path>
        <path class="cls-1" d="M954.87,114.61c0,60,42.39,108.95,104.77,108.95,43.28,0,67.16-10.15,67.16-10.15v-51.94h-4.18s-18.21,47.16-60.29,47.16c-45.67,0-63.28-48.65-63.28-99.99s22.69-94.92,62.98-94.92,60.59,46.56,60.59,46.56h4.18V10.74s-28.95-10.15-67.16-10.15c-51.34,0-104.77,39.1-104.77,114.02Z"></path>
        <path class="cls-1" d="M1222.1,66.17c-44.77,0-75.22,32.24-75.22,78.2s29.55,78.5,71.94,78.5c45.07,0,74.32-32.54,74.32-79.99,0-44.47-28.65-76.71-71.04-76.71Zm-2.39,142.98c-20.89,0-33.43-23.28-33.43-66.26,0-37.01,11.94-62.98,34.62-62.98,21.19,0,32.83,26.27,32.83,64.47,0,41.79-11.94,64.77-34.03,64.77Z"></path>
        <path class="cls-1" d="M1309.16,220.85h58.5v-2.69s-11.34-15.22-11.34-37.91V1.16l-47.16,4.78v2.69c6.57,5.37,11.34,23.58,11.34,40.89V180.26c0,22.69-11.34,37.91-11.34,37.91v2.69Z"></path>
        <path class="cls-1" d="M1386.38,220.85h58.5v-2.69s-11.34-15.22-11.34-37.91V1.16l-47.16,4.78v2.69c6.57,5.37,11.34,23.58,11.34,40.89V180.26c0,22.69-11.34,37.91-11.34,37.91v2.69Z"></path>
        <path class="cls-1" d="M1539.98,192.73c-22.98-2.69-39.7-21.49-39.7-49.85,0-.5,.01-.99,.02-1.49h103.86c0-42.98-22.69-75.22-65.07-75.22-44.77,0-78.2,32.24-78.2,78.2s27.76,78.5,70.74,78.5c37.31,0,60.29-20,70.14-50.44l-2.39-2.09c-12.54,14.63-33.13,25.37-59.4,22.39Zm-3.28-112.83c17.9,0,25.58,16.61,27.53,44.78l-63.38,5.59c2.8-30.17,15.83-50.38,35.84-50.38Z"></path>
        <path class="cls-1" d="M1852.82,192.73c-22.98-2.69-39.7-21.49-39.7-49.85,0-.5,.01-.99,.02-1.49h103.86c0-42.98-22.69-75.22-65.07-75.22-44.77,0-78.2,32.24-78.2,78.2s27.76,78.5,70.74,78.5c37.31,0,60.29-20,70.14-50.44l-2.39-2.09c-12.54,14.63-33.13,25.37-59.4,22.39Zm-3.28-112.83c17.9,0,25.58,16.61,27.53,44.78l-63.38,5.59c2.8-30.17,15.83-50.38,35.84-50.38Z"></path>
        <path class="cls-1" d="M1768.78,77.15l-17.31-34.49c-14.29,9.96-24.31,19.03-33.08,28.31-8.31-2.79-17.76-4.32-28.11-4.32-40.89,0-68.95,21.3-68.95,52.19,0,27.22,20.56,47.53,52.63,51.69l-36.81,18.49v5.1l29.88,12.6c-22.55,4.03-54.35,16.51-54.35,36.29,0,26.69,33.43,38.69,69.25,38.69,46.86,0,80.29-17.7,80.29-44.39,0-13.5-5.67-28.19-27.46-37.49l-52.83-22.19,12.61-6.6c36.67-2.67,60.82-23.44,60.82-53.09,0-16.02-7.49-29.55-20.38-38.68h32.32l1.49-2.1Zm-42.39,170.65c0,12.9-15.52,20.7-38.8,20.7s-41.49-12-41.49-29.69c0-12.77,18.45-22.71,35.87-25.79l22.04,9.29c18.51,7.8,22.39,16.5,22.39,25.49Zm-38.51-87.88c-17.91,0-28.65-16.5-28.65-41.99,0-23.09,12.54-39.89,29.85-39.89,18.21,0,28.95,15.9,28.95,40.79s-11.64,41.09-30.15,41.09Z"></path>
    </g>
    <g :style="{ 'fill': isDarkTheme ? 'white' : 'black'}">
        <g>
            <path class="cls-1" d="M198.11,371.48h-42.29c0,11.12,.22,20.02,.55,26.7,.22,4.78,1.09,8.34,3.38,11.34h-18.86c2.18-2.78,3.05-6.12,3.38-11.9,.33-6.67,.33-14.24,.33-28.36s0-23.03-.33-31.7c-.22-4.56-1.09-7.9-3.27-11.46h18.42c-2.29,3.67-3.27,7.56-3.38,12.68-.11,8.12-.22,16.35-.22,25.14h42.29c0-10.68,0-18.8-.33-26.36-.22-4.56-1.09-7.9-3.27-11.46h18.42c-2.29,3.67-3.27,7.56-3.38,12.68-.11,9.79-.11,19.69-.11,30.48,0,12.12,.11,21.8,.44,28.92,.22,4.78,1.09,8.34,3.38,11.34h-18.86c2.29-2.78,3.16-6.12,3.38-11.9,.44-6.34,.33-13.46,.33-26.14Z"></path>
            <path class="cls-1" d="M286.06,409.52c.87-1.45,1.31-2.89,1.31-4.45,0-2.56-1.2-6.01-2.29-9.01l-4.58-12.9h-29.54l-4.91,13.68c-.98,2.67-2.07,5.78-2.07,8.12,0,1.78,.43,3.22,1.52,4.56h-16.35c3.59-3.89,5.88-8.12,8.06-13.68l21.14-56.62c1.53-4.12,2.62-7.56,2.62-10.79,0-.89-.11-1.67-.22-2.34h13.3c-.11,.56-.22,1.22-.22,1.78,0,3.23,1.31,7.01,2.83,11.24l20.39,55.84c2.29,6.23,4.8,10.68,7.96,14.57h-18.97Zm-8.17-33.48l-11.77-33.15h-.66l-11.88,33.15h24.31Z"></path>
            <path class="cls-1" d="M325.95,398.29c.44-5.45,.44-12.79,.44-20.69v-19.58c0-8.12-.22-14.46-.44-20.35-.11-4.56-.98-8.23-3.16-11.57l9.92-.11c4.58,0,11.01-.22,16.46-.22,16.02,0,25.83,8.01,25.83,22.02,0,11.68-5.23,18.46-13.73,23.8,7.52,11.57,14.06,20.58,19.95,27.81,4.03,4.89,7.3,7.9,10.24,10.12h-16.35c-1.85,0-2.62-.22-3.49-1.56-2.51-3.67-5.67-8.68-10.35-16.35-3.93-6.56-7.63-11.79-10.14-15.79-.33-.56-.77-.67-1.42-.67l-11.88-.11c0,8.45,.11,14.91,.44,21.24,.22,5.34,.98,9.9,3.27,13.24h-18.86c2.4-2.89,3.05-7.01,3.27-11.24Zm36.84-46.83c0-11.79-5.67-18.8-17.55-18.8-4.03,0-5.77,.22-6.54,.78-.65,.34-.87,1.22-.87,2.34v30.26c0,.67,.33,1.11,.87,1.34,2.29,.78,5.56,1.22,9.49,1.22,9.7,0,14.6-6.45,14.6-17.13Z"></path>
            <path class="cls-1" d="M420.89,397.62c.33-6.34,.33-14.24,.33-28.36v-35.04h-17.99c-4.36,0-7.3,1.78-10.57,5.45l.22-13.79c2.94,.11,5.88,.22,8.94,.22h50.03c3.05,0,5.88-.11,8.94-.22l-.22,13.79c-3.38-3.67-5.89-5.45-10.25-5.45h-17.98v36.26c0,11.46,.11,20.91,.43,27.7,.22,4.78,1.09,8.34,3.49,11.34h-18.85c2.4-2.78,3.27-6.12,3.49-11.9Z"></path>
            <path class="cls-1" d="M482.79,397.4c.44-6.67,.33-16.46,.33-29.14,0-13.57,0-21.8-.33-30.48-.22-4.56-1.09-8.01-3.27-11.68h33.57c2.83,0,5.67-.11,8.5-.22v13.35c-3.16-3.34-5.99-5.12-10.24-5.12h-16.9v28.14h15.26c3.16,0,6.32-.34,8.94-.78l-.44,11.01c-2.4-2-4.91-2.89-8.07-2.89h-15.7v4.89c0,9.34,0,16.57,.22,22.91,.33,5.78,1.31,9.56,3.6,12.12h-18.86c2.18-2.89,3.05-6.34,3.38-12.12Z"></path>
            <path class="cls-1" d="M535.76,368.7c0-25.36,16.57-43.6,41.64-43.6s41.31,17.35,41.31,41.93-16.68,43.38-41.75,43.38c-23.54,0-41.2-17.02-41.2-41.71Zm70.41,.33c0-19.91-11.67-35.59-30.3-35.59-17.33,0-27.69,14.13-27.69,33.48,0,20.58,11.99,35.7,30.74,35.7,17.99-.11,27.25-14.35,27.25-33.59Z"></path>
            <path class="cls-1" d="M636.26,398.29c.44-5.45,.44-12.79,.44-20.69v-19.58c0-8.12-.22-14.46-.44-20.35-.11-4.56-.98-8.23-3.16-11.57l9.92-.11c4.58,0,11.01-.22,16.46-.22,16.02,0,25.83,8.01,25.83,22.02,0,11.68-5.23,18.46-13.73,23.8,7.52,11.57,14.06,20.58,19.95,27.81,4.03,4.89,7.3,7.9,10.24,10.12h-16.35c-1.85,0-2.62-.22-3.49-1.56-2.51-3.67-5.67-8.68-10.35-16.35-3.93-6.56-7.63-11.79-10.14-15.79-.33-.56-.77-.67-1.42-.67l-11.88-.11c0,8.45,.11,14.91,.44,21.24,.22,5.34,.98,9.9,3.27,13.24h-18.86c2.4-2.89,3.05-7.01,3.27-11.24Zm36.84-46.83c0-11.79-5.67-18.8-17.55-18.8-4.03,0-5.77,.22-6.54,.78-.65,.34-.87,1.22-.87,2.34v30.26c0,.67,.33,1.11,.87,1.34,2.29,.78,5.56,1.22,9.49,1.22,9.7,0,14.6-6.45,14.6-17.13Z"></path>
            <path class="cls-1" d="M725.09,409.52h-8.94c2.51-2.56,3.7-6.45,4.03-10.68,.44-6.12,.44-14.9,.44-29.03,0-13.24-.11-22.36-.33-32.26-.11-4.56-1.41-8.12-4.25-11.46h8.28c7.52,0,15.59-.45,23.87-.45,28.01,0,44.69,14.24,44.69,40.71,0,28.48-18.31,44.05-44.26,44.05-8.94,0-16.13-.89-23.54-.89Zm55.26-40.6c0-23.58-14.17-36.26-35.97-36.26-5.12,0-7.74,.34-9.16,.89-1.96,.78-2.73,2.34-2.84,4.56-.33,6.9-.43,23.02-.43,32.7,0,11.01,.33,20.91,.65,27.14,.11,2.45,1.09,3.56,3.16,4.34,3.16,1,8.28,1.67,13.84,1.67,19.29,0,30.74-12.9,30.74-35.04Z"></path>
            <path class="cls-1" d="M871.9,368.59c0-25.7,17-43.71,42.07-43.71,10.36,0,17.99,1.78,24.53,4.45l.76,15.57c-6.54-7.23-14.82-11.9-26.27-11.9-17.44,0-28.89,13.12-28.89,33.92,0,22.8,13.95,35.48,33.68,35.48,8.39,0,15.81-2.11,21.58-5.12l-2.83,8.9c-6,2.89-13.41,4.23-22.67,4.23-24.2,0-41.97-15.24-41.97-41.82Z"></path>
            <path class="cls-1" d="M954.74,368.7c0-25.36,16.57-43.6,41.64-43.6s41.31,17.35,41.31,41.93-16.68,43.38-41.75,43.38c-23.54,0-41.2-17.02-41.2-41.71Zm70.41,.33c0-19.91-11.66-35.59-30.3-35.59-17.33,0-27.68,14.13-27.68,33.48,0,20.58,11.99,35.7,30.74,35.7,17.99-.11,27.25-14.35,27.25-33.59Z"></path>
            <path class="cls-1" d="M1068.42,345.67h-.44v48.16c0,6,.98,11.79,3.92,15.68h-16.13c2.29-4.34,3.6-10.34,3.6-17.24,.22-15.24,.33-39.15,.44-50.28-.11-5.45-.11-10.79-.22-16.57h6.54l55.04,63.62h.55c0-15.13-.11-30.81-.11-45.49,0-7.68-1.31-13.24-4.03-17.46h16.67c-2.62,4-3.49,8.56-3.59,15.13-.22,13.57-.55,38.15-.44,50.72,0,6.12,.11,12.24,.22,18.47h-5.67l-56.35-64.74Z"></path>
            <path class="cls-1" d="M1170,345.67h-.44v48.16c0,6,.98,11.79,3.92,15.68h-16.13c2.29-4.34,3.6-10.34,3.6-17.24,.22-15.24,.33-39.15,.44-50.28-.11-5.45-.11-10.79-.22-16.57h6.54l55.04,63.62h.55c0-15.13-.11-30.81-.11-45.49,0-7.68-1.31-13.24-4.03-17.46h16.68c-2.62,4-3.49,8.56-3.6,15.13-.22,13.57-.54,38.15-.43,50.72,0,6.12,.11,12.24,.22,18.47h-5.67l-56.35-64.74Z"></path>
            <path class="cls-1" d="M1294.8,409.52h-35.32c2.51-2.89,3.16-6.23,3.38-12.12,.44-6.67,.44-16.46,.44-29.14,0-13.57,0-21.8-.44-30.48-.22-4.56-1.09-8.12-3.27-11.68h34.01c2.94,0,5.78-.11,8.61-.22v13.24c-3.16-3.23-5.99-5-10.35-5h-17.22v28.03h16.13c3.16,0,6.21-.22,8.83-.67l-.44,11.01c-2.4-2-4.8-2.89-7.96-2.89h-16.57v4.78c0,9.9,.11,18.69,.22,24.92,0,1.78,.87,2.67,2.83,2.78h16.24c4.25,0,7.09-1.89,10.24-5.12l-.43,12.68c-2.95-.11-5.78-.11-8.94-.11Z"></path>
            <path class="cls-1" d="M1323.25,368.59c0-25.7,17-43.71,42.07-43.71,10.36,0,17.99,1.78,24.53,4.45l.76,15.57c-6.54-7.23-14.82-11.9-26.27-11.9-17.44,0-28.89,13.12-28.89,33.92,0,22.8,13.95,35.48,33.68,35.48,8.39,0,15.81-2.11,21.58-5.12l-2.83,8.9c-6,2.89-13.41,4.23-22.67,4.23-24.2,0-41.97-15.24-41.97-41.82Z"></path>
            <path class="cls-1" d="M1434.21,397.62c.33-6.34,.33-14.24,.33-28.36v-35.04h-17.99c-4.36,0-7.3,1.78-10.57,5.45l.22-13.79c2.94,.11,5.88,.22,8.94,.22h50.03c3.05,0,5.88-.11,8.94-.22l-.22,13.79c-3.38-3.67-5.89-5.45-10.25-5.45h-17.98v36.26c0,11.46,.11,20.91,.43,27.7,.22,4.78,1.09,8.34,3.49,11.34h-18.85c2.4-2.78,3.27-6.12,3.49-11.9Z"></path>
            <path class="cls-1" d="M1495.9,397.62c.44-6.67,.33-14.24,.33-28.25s0-23.14-.33-31.81c-.22-4.56-1.09-8.01-3.27-11.46h18.64c-2.29,3.56-3.27,7.56-3.38,12.68-.11,9.79-.22,19.69-.22,30.59,0,12.01,.11,21.69,.55,28.81,.22,4.89,1.09,8.45,3.38,11.34h-19.07c2.18-2.67,3.05-6.01,3.38-11.9Z"></path>
            <path class="cls-1" d="M1530.24,368.59c0-25.7,17-43.71,42.07-43.71,10.36,0,17.99,1.78,24.53,4.45l.76,15.57c-6.54-7.23-14.82-11.9-26.27-11.9-17.44,0-28.89,13.12-28.89,33.92,0,22.8,13.95,35.48,33.68,35.48,8.39,0,15.81-2.11,21.58-5.12l-2.83,8.9c-6,2.89-13.41,4.23-22.67,4.23-24.2,0-41.97-15.24-41.97-41.82Z"></path>
            <path class="cls-1" d="M1619.07,380.27c0-18.58,0-30.81-.33-43.49-.11-4.12-.87-7.23-3.49-10.68h19.08c-3.06,3.23-3.93,6.67-3.93,10.9,0,14.35-.22,26.36-.22,41.93s9.27,23.36,23.11,23.36,22.02-7.56,21.91-22.36l-.33-43.6c0-4.45-1.41-7.45-4.47-10.23h17.22c-2.94,2.56-3.81,5.67-3.81,9.9,0,15.46-.11,29.03-.11,44.49,0,19.47-13.19,29.92-32.04,29.92s-32.59-9.68-32.59-30.14Z"></path>
            <path class="cls-1" d="M1728.72,397.62c.33-6.34,.33-14.24,.33-28.36v-35.04h-17.99c-4.36,0-7.3,1.78-10.57,5.45l.22-13.79c2.94,.11,5.89,.22,8.94,.22h50.03c3.05,0,5.88-.11,8.94-.22l-.22,13.79c-3.38-3.67-5.89-5.45-10.25-5.45h-17.99v36.26c0,11.46,.11,20.91,.44,27.7,.22,4.78,1.09,8.34,3.49,11.34h-18.86c2.4-2.78,3.27-6.12,3.49-11.9Z"></path>
        </g>
        <path class="cls-1" d="M832.65,377.2c5.41,0,9.81-4.4,9.81-9.94s-4.4-9.81-9.81-9.81-9.94,4.4-9.94,9.81,4.4,9.94,9.94,9.94Z"></path>
    </g>
  </svg>
</template>

<script>
export default {
        name: 'TrinityLogo',
        props: {
            height: {
                type: Number,
                required: false,
                default: null
            },
            isDarkTheme: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        components: {
        },
        setup() {
        }
    }
</script>

<style>

</style>