<template>
    <div class="bg-transparent pa-0 ma-0">
        <v-img
          class="pa-0" 
          :src="require('@/assets/images/garage-tools-wall.jpg')"
          :height="height"
          :style="{ opacity: 0.05 }"
          cover
        ></v-img>
        <v-overlay
            class="d-flex justify-center align-center"
            persistent
            v-model="showResumeOverlay"
            :close-on-back="false"
            :scrim="false"
            :style="{ 'max-height': (height) + 'px' }"
            contained
        >   
            <Resume :height="height"></Resume>
        </v-overlay>
    </div>
</template>
  
<script>
  import { ref } from 'vue'
  import { useDisplay } from 'vuetify'
  import Resume from './Resume.vue'
  export default {
    name: 'About',
    head() {
    return {
      // Children can override the title.
      title: `About`,
      // Result: My Page Title ← My Site
      // If a child changes the title to "My Other Page Title",
      // it will become: My Other Page Title ← My Site
      titleTemplate: `MHPS v${process.env.PACKAGE_VERSION} | %s`,
      // Define meta tags here.
      meta: [
        {name: 'description', content: `About`},
        // OpenGraph data (Most widely used)
        {property: 'og:title', content: 'About'},
        {property: 'og:site_name', content: 'About'},
        // The list of types is available here: http://ogp.me/#types
        {property: 'og:type', content: 'website'},
        // Should the the same as your canonical link, see below.
        {property: 'og:url', content: 'https://www.mjchp.com/about'},
        {property: 'og:image', content: 'https://www.mjchp.com/favicon.png'},
        // Often the same as your meta description, but not always.
        {property: 'og:description', content: 'About'},
        // Google / Schema.org markup:
        {itemprop: 'name', content: `About`},
        {itemprop: 'description', content: `About`},
        {itemprop: 'image', content: 'https://www.mjchp.com/favicon.png'}
      ],
      link: [
        {rel: 'canonical', href: 'https://www.mjchp.com/about'}
      ]
    }
  },
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        }
    },
    components: {
        Resume
    },
    setup() {
        const showResumeOverlay = ref(true)

        // Window Size Mgmt
        const { mdAndUp, smAndUp } = useDisplay()

        return {
        showResumeOverlay,
        // Window Size Management
        smAndUp,
        mdAndUp
      }
    }
  }
  </script>
  
<style lang="scss" scoped>
  
</style>