<template>
  <ResumePrinter v-if="currentPath === '/resume'" :width="windowWidth" :height="windowHeight" :homeFunction="visitHome"/>
  <Contract v-else-if="currentPath === '/contract'" :height="windowHeight" :width="windowWidth" :homeFunction="visitHome"/>
  <LogoPrinter v-else-if="currentPath === '/logo'" :height="windowHeight" :width="windowWidth" :homeFunction="visitHome"/>
  <v-app v-else :theme="currentTheme" :style="{ 'height': windowHeight + 'px' }">
    <v-main class="flex-grow-0" :style="{ 'height': mainContentHeight + 'px' }">
      <Home v-if="currentPath === '/'" :height="mainContentHeight"/>
      <v-banner
        class="pickup-color sleigher-font"
        v-if="currentPath === '/' && mdAndUp"
        lines="three"
        :stacked="true"
        elevation="8"
        :style="{
            'height': '380px', 'position': 'absolute',
            'width': '400px',
            'right': `15px`,
            'bottom': `${(footerContentHeight + 15)}` + 'px'
          }"
      >
        <template v-slot:text>
          <div :style="{ 'line-height': '32px', 'width': '360px' }">
            <p>
              <span style="font-size: 24px;">Featured Project</span>
              <br>
              <span style="font-size: 60px; color: #FFF; cursor: pointer;" @click="visitURL('https://trak.watch')">trak.watch</span>
            </p>
            <VideoBackground
              class="pl-2"
              :src="require('@/assets/captures/trakWatch_portfolio.mp4')"
              :playBackRate="0.1"
              :object-fit="scale-down"
              :style="{ 'height': '200px', 'width': '100%' }"
            ></VideoBackground>
          </div>
        </template>
        <template v-slot:actions>
          <v-btn class="pr-6" variant="plain" style="font-size: 24px;" @click="visitProjects">View All Projects</v-btn>
        </template>
      </v-banner>
      <Projects v-if="currentPath === '/projects'" :height="mainContentHeight"/>
      <About v-if="currentPath === '/about'" :height="mainContentHeight"/>
      <v-dialog v-model="contactDialog" :fullscreen="!smAndUp" :style="{ 'max-width': '985px' }">
        <v-card>
          <v-card-title class="pickup-color sleigher-font">Contact Mike's High Performance Software</v-card-title>
          <v-card-text>
            For all inquiries, send an email to '{{ contactEmail }}' or feel free to reach out on <a class="pickup-color sleigher-font clickable" @click="visitMJCLinkedIn"><LinkedInLogo :height="15"/></a>.
          </v-card-text>
          <v-card-actions>
            <v-btn class="pickup-color sleigher-font" block @click="contactDialog = false">Dismiss</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <v-card class="bg-transparent" :height="footerContentHeight" elevation="8">
      <div :class="['d-flex', 'justify-space-between', 'align-center', (mdAndUp ? '' : 'flex-column') ]" :style="{ 'width': '100%' }">
        <div v-if="!mdAndUp" class="d-flex justify-center align-center flex-column pl-3 pr-6 pt-0 pb-0 mt-0 mb-0">
          <div class="d-flex justify-center align-center">
            <MHPSLogo class="py-2" @click="visitHome" />
          </div>
          <div class="d-flex justify-center align-center">
            <v-btn class="ml-3 mr-3" :size="pageLinkButtonSize" variant="plain" @click="visitProjects">
              <div class="pickup-color sleigher-font">Projects</div>
            </v-btn>
            <v-btn :size="pageLinkButtonSize" variant="plain" @click="visitAbout">
              <div class="pickup-color sleigher-font">About</div>
            </v-btn>
            <v-btn class="ml-3" :size="pageLinkButtonSize" variant="plain" @click="showContactDialog">
              <div class="pickup-color sleigher-font">Contact</div>
            </v-btn>
            <v-btn class="ml-3" :size="pageLinkButtonSize" variant="plain" @click="visitClientPortal">
              <div class="pickup-color sleigher-font">Client Portal</div>
            </v-btn>
          </div>
        </div>
        <div v-if="mdAndUp" class="d-flex justify-center align-center pl-3 pr-6 pt-0 pb-0 mt-0 mb-0">
          <MHPSLogo class="ml-3 mr-3" @click="visitHome" />
          <v-btn class="ml-3 mr-3" :size="pageLinkButtonSize" variant="plain" @click="visitProjects">
            <div class="pickup-color sleigher-font">Projects</div>
            </v-btn>
            <v-btn :size="pageLinkButtonSize" variant="plain" @click="visitAbout">
              <div class="pickup-color sleigher-font">About</div>
            </v-btn>
            <v-btn class="ml-3" :size="pageLinkButtonSize" variant="plain" @click="showContactDialog">
              <div class="pickup-color sleigher-font">Contact</div>
            </v-btn>
            <v-btn class="ml-3" :size="pageLinkButtonSize" variant="plain" @click="visitClientPortal">
              <div class="pickup-color sleigher-font">Client Portal</div>
            </v-btn>
        </div>
        <div class="d-flex justify-center align-center pl-6 pr-3 pt-0 pb-0 mt-0 mb-0">
            <v-btn class="pickup-color" size="small" variant="plain" icon="mdi-linkedin" @click="visitMJCLinkedIn">
            </v-btn>
            <v-btn class="pickup-color" size="small" variant="plain" icon="mdi-github" @click="visitMJCGithub">
            </v-btn>
            <v-btn class="pickup-color" size="small" variant="plain" icon="mdi-stack-overflow" @click="visitMJCStackOverflow">
            </v-btn>
            <v-btn size="x-small" variant="plain" disabled>
            <div class="pickup-color">v{{ projectVersion }}</div>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, inject, ref, watch } from 'vue'
import { useRoute, useRouter } from "vue-router"
// import { useAuth0 } from '@auth0/auth0-vue'
import { useDisplay } from 'vuetify'
import Home from './components/Home.vue'
import Projects from './components/Projects.vue'
import About from './components/About.vue'
import ResumePrinter from './components/ResumePrinter.vue'
import Contract from './components/Contract.vue'
import LinkedInLogo from './components/logos/LInkedInLogo.vue'
import MHPSLogo from './components/logos/MHPSLogo.vue'
import LogoPrinter from './components/LogoPrinter.vue'
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  name: 'App',
  head() {
    return {
    // Children can override the title.
    title: `MHPS v${process.env.PACKAGE_VERSION}`,
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: '%s',
    // Define meta tags here.
    meta: [
      {name: 'description', content: 'Mike\'s High Performance Software'},
      // OpenGraph data (Most widely used)
      {property: 'og:title', content: 'Mike\'s High Performance Software'},
      {property: 'og:site_name', content: 'Mike\'s High Performance Software'},
      // The list of types is available here: http://ogp.me/#types
      {property: 'og:type', content: 'website'},
      // Should the the same as your canonical link, see below.
      {property: 'og:url', content: 'https://www.mjchp.com/'},
      {property: 'og:image', content: 'https://www.mjchp.com/favicon.png'},
      // Often the same as your meta description, but not always.
      {property: 'og:description', content: 'Mike\'s High Performance Software'},
      // Google / Schema.org markup:
      {itemprop: 'name', content: `MHPS v${process.env.PACKAGE_VERSION}`},
      {itemprop: 'description', content: 'Mike\'s High Performance Software'},
      {itemprop: 'image', content: 'https://www.mjchp.com/favicon.png'}
    ],
    link: [
      {rel: 'canonical', href: 'https://www.mjchp.com/'}
    ]
  }
  },
  components: {
    Home,
    Projects,
    About,
    ResumePrinter,
    Contract,
    LinkedInLogo,
    MHPSLogo,
    LogoPrinter,
    VideoBackground
},
  setup() {
    // Logger
    const logger = inject('vuejs3-logger')

    // Router
    const router = useRouter()
    const route = useRoute()

    // App Constants
    const componentTitle = ref(`Mike's High Performance Software`)
    const projectVersion = ref(process.env.PACKAGE_VERSION || '0')
    const contactEmail = ref('mikes.high.performance.software' + '@' + 'gmail.com')

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()
    const windowHeight = ref(window.innerHeight)
    const windowWidth = ref(window.innerWidth)
    const footerHeight = ref(75)
    const smallScreenFooterMultiplier = ref(2)

    watch(windowHeight, (newHeight, oldHeight) => {
      logger.debug(`Window height: ${oldHeight} --> ${newHeight}`)
    })
    watch(windowWidth, (newWidth, oldWidth) => {
      logger.debug(`Window width: ${oldWidth} --> ${newWidth}`)
    })

    const windowResizeHandler = () => {
      windowHeight.value = window.innerHeight
      windowWidth.value = window.innerWidth
    }

    onMounted(() => {
      window.addEventListener('resize', windowResizeHandler)   
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', windowResizeHandler)
    })

    const footerContentHeight = computed(() => {
      return mdAndUp.value ? footerHeight.value : footerHeight.value * smallScreenFooterMultiplier.value
    })

    const mainContentHeight = computed(() => {
      return windowHeight.value - footerContentHeight.value
    })

    const pageLinkButtonSize = ref('normal')
    
    // Theme Mgmt
    const currentTheme = ref('dark')
    const darkThemeSwitch = ref(true)

    watch(darkThemeSwitch, (val) => {
      if (val) {
        currentTheme.value = 'dark'
      } else {
        currentTheme.value = 'light'
      }
    })

    // Internal Links
    const currentPath = ref('/')
    watch(
      () => route.path,
      newPath => {
        logger.debug(`Route path changed to: ${JSON.stringify(newPath)}`)
        currentPath.value = newPath
      },
      { immediate: true }
    )

    const checkRouteAndPush = (newRoute) => {
      if (currentPath.value !== newRoute) {
        router.push(newRoute)
      }
    }

    const visitHome = () => {
      checkRouteAndPush('/')
    }

    const visitProjects = () => {
      checkRouteAndPush('/projects')
    }

    const visitAbout = () => {
      checkRouteAndPush('/about')
    }

    const visitResumePrinter = () => {
      checkRouteAndPush('/resume')
    }

    // External Links

    const visitURL = (url) => {
        window.open(url);
      }

    const visitMJCLinkedIn = () => {
      window.open('https://www.linkedin.com/in/michael-cross-2aaa8527')
    }

    const visitMJCGithub = () => {
      window.open('https://github.com/ir0nCr055')
    }

    const visitMJCStackOverflow = () => {
      window.open('https://stackoverflow.com/users/2333933/mike-c?tab=answers')
    }

    const visitClientPortal = () => {
      window.open('https://mhps.invoicing.co/client/login')
    }

    // Contact Dialog
    const contactDialog = ref(false)
    const showContactDialog = () => {
      contactDialog.value = true
    }

    // User Authentication
    // const loginBenefitMesssage = ref('Sign up for free to save your tracking numbers!')
    // const { getAccessTokenSilently, loginWithRedirect, logout, user, isAuthenticated } = useAuth0()
    // const logInOrOut = () => {
    //   if (isAuthenticated.value === true) {
    //     // Log out
    //     logout({ returnTo: window.location.origin })
    //   } else {
    //     loginWithRedirect()
    //   }
    // }

    // Maintain Logged In User Message
    // const loggedInUserMessage = ref('')
    // watch(user, (thisUser) => {
    //   if (thisUser?.name) {
    //     loggedInUserMessage.value = `Logged In As: ${thisUser.name}`
    //   } else {
    //     loggedInUserMessage.value = ''
    //   }
    // }, { immediate: true, deep: true })


    // Load User content when auth status changes to true
    // watch(isAuthenticated, async (isAuthd) => {
    //   if (isAuthd) {
    //     // TODO
    //   }
    // })

    return {
      componentTitle,
      projectVersion,
      currentTheme,
      darkThemeSwitch,
      // Responsiveness
      mdAndUp, // size class bool
      smAndUp, // size class bool
      windowHeight,
      windowWidth,
      footerContentHeight,
      mainContentHeight,
      footerHeight,
      smallScreenFooterMultiplier,
      // Button Sizing
      pageLinkButtonSize,
      // Internal Links
      currentPath,
      visitURL,
      visitHome,
      visitProjects,
      visitAbout,
      visitResumePrinter,
      // External Links
      visitMJCLinkedIn,
      visitMJCGithub,
      visitMJCStackOverflow,
      visitClientPortal,
      // Contact Dialog
      contactEmail,
      contactDialog,
      showContactDialog
    }
  }
}
</script>

<style lang="scss" scoped>

a:link {
  color: var(--pickup-color);
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: var(--pickup-color);
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: black;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  color: var(--pickup-color);
  background-color: transparent;
  text-decoration: none;
}

</style>