import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import { VueHeadMixin, createHead } from '@unhead/vue'
import VueLogger from 'vuejs3-logger'
// import { createAuth0 } from '@auth0/auth0-vue'
import { createRouter, createWebHistory } from 'vue-router'
import '@/assets/css/global.css'

const isProd = process.env.VUE_APP_ORIGIN?.startsWith('https://')

const loggerOpts = {
  isEnabled: true,
  logLevel : isProd ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}

loadFonts()

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: App },
    { path: '/projects', component: App },
    { path: '/about', component: App },
    { path: '/resume', component: App },
    { path: '/logo', component: App },
    { path: '/contract', component: App },
    { path: '/:pathMatch(.*)*', redirect: '/' }
  ]
})

createApp(App)
  .use(vuetify)
  .use(router)
  .use(createHead())
  .mixin(VueHeadMixin)
  .use(mdiVue, {
    icons: mdijs
  })
  .use(VueLogger, loggerOpts)
  // .use(
  //   createAuth0({
  //     domain: "",
  //     client_id: "",
  //     redirect_uri: window.location.origin,
  //     audience: 'hasura'
  //   })
  // )
  .mount('#app')
