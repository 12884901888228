<template>
    <div class="d-flex flex-column justify-center align-center"
        :style="{ position: 'fixed', top: '30px', right: '10px', transform: 'translate(-50%, -50%)', 'z-index': 1000 }">
        <v-btn class="no-print" size="medium" icon="mdi-home-circle-outline" @click="goHome"></v-btn>
        <v-btn class="no-print mt-2" size="medium" icon="mdi-cog-outline" @click="openSettings"></v-btn>
    </div>
    <v-theme-provider theme="light">
        <div class="d-flex flex-column align-center justify-center pt-3">
            <div id="contract"
                :style="{ 'user-select': 'none', 'max-width': '950px' }">
                <div id="outputPage">
                    <div class="outputVersion1 template_SERVAG templated">
                        <div>
                            <h1>CONSULTING AGREEMENT</h1>
                            <p><strong>THIS CONSULTING AGREEMENT (the "Agreement") is dated this </strong><span class="paramField">_{{
                                    contractData.contractDay.value || '__________' }}_</span><strong> day of
                                </strong><span class="paramField">_{{ contractData.contractMonth.value || '______________'
                                    }}_</span><strong>,</strong> <span class="paramField">_{{ contractData.contractYear.value || '_________'
                                    }}_</span><strong>.</strong>
                            </p>
                            <div class="partiesContainer">
                                <div class="parties">
                                    <div class="single">
                                        <h2>
                                            Client
                                        </h2>
                                        <div class="detail">
                                            <span class="name">
                                                <span v-if="contractData.client1.value" class="paramField">{{ '_' +
                                                    contractData.client1.value + '_' }}</span>
                                                <span v-else>______________________</span>
                                            </span>
                                            <span class="address">
                                                <span v-if="contractData.clientAddress1.value" class="paramField">{{ '_' +
                                                    contractData.clientAddress1.value + '_' }}</span>
                                                <span v-else>______________________</span>
                                            </span>
                                            <span class="address">
                                                <span v-if="contractData.clientAddress2.value" class="paramField">{{ '_' +
                                                    contractData.clientAddress2.value + '_' }}</span>
                                            </span>
                                        </div><span class="titleSentence">(the "Client")</span>
                                    </div>
                                    <div class="single">
                                        <h2>
                                            Consultant
                                        </h2>
                                        <div class="detail">
                                            <span class="name">
                                                <span v-if="contractData.consultant1.value" class="paramField">{{ '_' +
                                                    contractData.consultant1.value + '_' }}</span>
                                                <span v-else>______________________</span>
                                            </span>
                                            <span class="address">
                                                <span v-if="contractData.consultantAddress1.value" class="paramField">{{ '_' +
                                                    contractData.consultantAddress1.value + '_' }}</span>
                                                <span v-else>______________________</span>
                                            </span>
                                            <span class="address">
                                                <span v-if="contractData.consultantAddress2.value" class="paramField">{{ '_' +
                                                    contractData.consultantAddress2.value + '_' }}</span>
                                            </span>
                                        </div><span class="titleSentence">(the "Consultant")</span>
                                    </div>
                                </div>
                            </div>
                            <ol class=" recital" start="1">
                                <li class="lh"><span>BACKGROUND</span><br>
                                </li>
                                <li value="1"><span>The</span> Client is of the opinion that the Consultant has the
                                    necessary
                                    qualifications, experience and abilities to provide consulting services to the
                                    Client.<br>
                                </li>
                                <li value="2"><span>The</span> Consultant is agreeable to providing such consulting
                                    services
                                    to
                                    the Client on the terms and conditions set out in this Agreement.<br>
                                </li>
                            </ol>
                            <div class=" body">
                                <p class="opening"><strong>IN CONSIDERATION OF</strong> the matters described above and
                                    of
                                    the
                                    mutual benefits and obligations set forth in this Agreement, the receipt and
                                    sufficiency
                                    of
                                    which consideration is hereby acknowledged, the Client and the Consultant
                                    (individually
                                    the
                                    "Party" and collectively the "Parties" to this Agreement) agree as follows:
                                </p>
                                <ol start="1">
                                    <li class="lh"><span>Services Provided</span><strong><u> </u></strong><br>
                                    </li>
                                    <li value="1"><span>The</span> Client hereby agrees to engage the Consultant to
                                        provide
                                        the
                                        Client with the following consulting services (the "Services"):<br>
                                        <ul>
                                            <li v-for="(service, i) in contractData.contractServices.value" :value="i" :key="i">
                                                <div><span>{{ service }}</span><br>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li value="2"><span>The Services will also include any other</span> consulting tasks
                                        which
                                        the Parties may agree on. The Consultant hereby agrees to provide such Services
                                        to
                                        the
                                        Client.<br>
                                    </li>
                                    <li class="lh"><span>Term of </span><strong><u>
                                            </u></strong><strong><u>Agreement</u></strong><br>
                                    </li>
                                    <li value="3"><span>The term of this</span> Agreement (the "Term") will begin on the
                                        date of
                                        this Agreement and will remain in full force and effect indefinitely until
                                        terminated as
                                        provided in this Agreement.<br>
                                    </li>
                                    <li value="4"><span>In the event that either Party wishes to terminate this</span>
                                        Agreement, that Party will be required to provide 15 days' written notice to the
                                        other
                                        Party.<br>
                                    </li>
                                    <div class="pagebreakafter"></div>
                                    <div class="pagebreakbefore"></div>
                                    <li value="5"><span>In the event that either Party breaches a material provision
                                            under
                                            this</span> Agreement, the non-defaulting Party may terminate this Agreement
                                        immediately and require the defaulting Party to indemnify the non-defaulting
                                        Party
                                        against all reasonable damages. <br>
                                    </li>
                                    <li value="6"><span>This</span> Agreement may be terminated at any time by mutual
                                        agreement
                                        of the Parties.<br>
                                    </li>
                                    <li value="7"><span>Except as otherwise provided in this</span> Agreement, the
                                        obligations
                                        of the Consultant will end upon the termination of this Agreement.<br>
                                    </li>
                                    <li class="lh"><span>Performance</span><br>
                                    </li>
                                    <li value="8"><span>The Parties agree to do everything necessary to ensure that the
                                            terms of
                                            this</span> Agreement take effect.<br>
                                    </li>
                                    <li class="lh"><span>Currency</span><br>
                                    </li>
                                    <li value="9"><span>Except as otherwise provided in this</span> Agreement, all
                                        monetary
                                        amounts referred to in this Agreement are in USD (US Dollars).<br>
                                    </li>
                                    <li class="lh"><span>Compensation</span><br>
                                    </li>
                                    <li value="10"><span>The</span> Consultant will charge the Client for the Services
                                        at
                                        the
                                        rate of ${{ contractData.contractHourlyRate.value || '_________' }} per hour (the
                                        "Compensation").<br>
                                    </li>
                                    <li value="11"><span>The Client will be invoiced every month.</span><br>
                                    </li>
                                    <li value="12"><span>Invoices submitted by the Consultant to the Client are due
                                            within
                                            30
                                            days of receipt.</span><br>
                                    </li>
                                    <li value="13"><span>In the event that the Agreement is terminated early by either
                                            Party
                                            in
                                            accordance with clause 4, the Consultant may invoice the Client on a pro
                                            rata
                                            basis
                                            for work done up to and including the termination date.</span><br>
                                    </li>
                                    <li class="lh"><span>Reimbursement of Expenses</span><br>
                                    </li>
                                    <li value="14"><span>The Consultant will be reimbursed from time to time for
                                            reasonable
                                            and
                                            necessary expenses incurred by the Consultant in connection with providing
                                            the
                                            Services. </span><br>
                                    </li>
                                    <li value="15"><span>All expenses must be pre-approved by the</span> Client.<br>
                                    </li>
                                    <li class="lh"><span>Confidentiality</span><br>
                                    </li>
                                    <li value="16"><span>Confidential information (the "Confidential Information")
                                            refers to
                                            any
                                            data or information relating to the Client, whether business or personal,
                                            which
                                            would reasonably be considered to be private or proprietary to the Client
                                            and
                                            that
                                            is not generally known and where the release of that Confidential
                                            Information
                                            could
                                            reasonably be expected to cause harm to the Client.</span><br>
                                    </li>
                                    <li value="17"><span>The Consultant agrees that they will not disclose, divulge,
                                            reveal,
                                            report or use, for any purpose, any Confidential Information which the
                                            Consultant
                                            has obtained, except as authorized by the Client or as required by law. The
                                            obligations of confidentiality will apply during the Term and will survive
                                            indefinitely upon termination of this Agreement.</span><br>
                                    </li>
                                    <div class="pagebreakafter"></div>
                                    <div class="pagebreakbefore"></div>
                                    <li value="18"><span>All written and oral information and material disclosed or
                                            provided
                                            by
                                            the</span> Client to the Consultant under this Agreement is Confidential
                                        Information
                                        regardless of whether it was provided before or after the date of this Agreement
                                        or
                                        how
                                        it was provided to the Consultant.<br>
                                    </li>
                                    <li class="lh"><span>Ownership of Intellectual Property</span><br>
                                    </li>
                                    <li value="19"><span>All</span> intellectual property and related material,
                                        including
                                        any
                                        trade secrets, moral rights, goodwill, relevant registrations or applications
                                        for
                                        registration, and rights in any patent, copyright, trademark, trade dress,
                                        industrial
                                        design and trade name (the "Intellectual Property") that is developed or
                                        produced
                                        under
                                        this Agreement, is a "work made for hire" and will be the sole property of the
                                        Client.
                                        The use of the Intellectual Property by the Client will not be restricted in any
                                        manner.<br>
                                    </li>
                                    <li value="20"><span>The</span> Consultant may not use the Intellectual Property for
                                        any
                                        purpose other than that contracted for in this Agreement except with the written
                                        consent
                                        of the Client. The Consultant will be responsible for any and all damages
                                        resulting
                                        from
                                        the unauthorized use of the Intellectual Property.<br>
                                    </li>
                                    <li class="lh"><span>Return of Property</span><br>
                                    </li>
                                    <li value="21"><span>Upon the expiration or termination of this</span> Agreement,
                                        the
                                        Consultant will return to the Client any property, documentation, records, or
                                        Confidential Information which is the property of the Client.<br>
                                    </li>
                                    <li class="lh"><span>Capacity/Independent Contractor</span><br>
                                    </li>
                                    <li value="22"><span>In providing the Services under this</span> Agreement it is
                                        expressly
                                        agreed that the Consultant is acting as an independent contractor and not as an
                                        employee. The Consultant and the Client acknowledge that this Agreement does not
                                        create
                                        a partnership or joint venture between them, and is exclusively a contract for
                                        service.
                                        The Client is not required to pay, or make any contributions to, any social
                                        security,
                                        local, state or federal tax, unemployment compensation, workers' compensation,
                                        insurance
                                        premium, profit-sharing, pension or any other employee benefit for the
                                        Consultant
                                        during
                                        the Term. The Consultant is responsible for paying, and complying with reporting
                                        requirements for, all local, state and federal taxes related to payments made to
                                        the
                                        Consultant under this Agreement.<br>
                                    </li>
                                    <!-- <li class="lh"><span>Right of Substitution</span><br>
                                                                    </li>
                                                                    <li value="23"><span>Except as otherwise provided in this Agreement, the Consultant may,
                                                                            at
                                                                            the Consultant's absolute discretion, engage a third party sub-contractor to
                                                                            perform
                                                                            some or all of the obligations of the Consultant under this Agreement and the
                                                                            Client
                                                                            will not hire or engage any third parties to assist with the provision of the
                                                                            Services. </span><br>
                                                                    </li>
                                                                    <li value="24"><span>In the event that the Consultant hires a sub-contractor:</span><br>
                                                                        <ul>
                                                                            <li value="1"><span>the Consultant will pay the sub-contractor for its services
                                                                                    and
                                                                                    the Compensation will remain payable by the Client to the
                                                                                    Consultant.</span><br>
                                                                            </li>
                                                                            <li value="2"><span>for the purposes of the indemnification clause of this
                                                                                    Agreement, the sub-contractor is an agent of the Consultant.</span><br>
                                                                            </li>
                                                                        </ul>
                                                                    </li> -->
                                    <li class="lh"><span>Autonomy</span><br>
                                    </li>
                                    <li value="25"><span>Except as otherwise provided in this Agreement, the Consultant
                                            will
                                            have full control over working time, methods, and decision making in
                                            relation to
                                            provision of the Services in accordance with the Agreement. The Consultant
                                            will
                                            work
                                            autonomously and not at the direction of the Client. However, the Consultant
                                            will be
                                            responsive to the reasonable needs and concerns of the Client. </span><br>
                                    </li>
                                    <li class="lh"><span>Equipment</span><br>
                                    </li>
                                    <li value="26"><span>Except as otherwise provided in this Agreement, the Consultant
                                            will
                                            provide at the Consultant’s own expense, any and all equipment, software,
                                            materials
                                            and any other supplies necessary to deliver the Services in accordance with
                                            the
                                            Agreement.</span><br>
                                    </li>
                                    <li class="lh"><span>No Exclusivity</span><br>
                                    </li>
                                    <li value="27"><span>The Parties acknowledge that this Agreement is non-exclusive
                                            and
                                            that
                                            either Party will be free, during and after the Term, to engage or contract
                                            with
                                            third parties for the provision of services similar to the
                                            Services.</span><br>
                                    </li>
                                    <div class="pagebreakafter"></div>
                                    <div class="pagebreakbefore"></div>
                                    <li class="lh"><span>Notice</span><br>
                                    </li>
                                    <li value="28"><span>All notices, requests, demands or other communications required
                                            or
                                            permitted by the terms of this</span> Agreement will be given in writing and
                                        delivered to the Parties at the following addresses: <br>
                                        <ol start="1">
                                            <li value="1">
                                                <span class="name">
                                                    <span v-if="contractData.client1.value" class="paramField">{{ '_' +
                                                        contractData.client1.value + '_' }}</span>
                                                    <span v-else>______________________</span>
                                                </span>
                                                <br>
                                                <span class="address">
                                                    <span v-if="contractData.clientAddress1.value" class="paramField">{{ '_' +
                                                        contractData.clientAddress1.value + '_' }}</span>
                                                    <span v-else>______________________</span>
                                                </span>
                                                <br>
                                            </li>
                                            <li value="2">
                                                <span class="name">
                                                    <span v-if="contractData.consultant1.value" class="paramField">{{ '_' +
                                                        contractData.consultant1.value + '_' }}</span>
                                                    <span v-else>______________________</span>
                                                </span>
                                                <br>
                                                <span class="address">
                                                    <span v-if="contractData.consultantAddress1.value" class="paramField">{{
                                                        '_' + contractData.consultantAddress1.value + '_' }}</span>
                                                    <span v-else>______________________</span>
                                                </span>
                                                <br>
                                            </li>
                                        </ol>
                                        <p>or to such other address as either Party may from time to time notify the
                                            other,
                                            and
                                            will be deemed to be properly delivered (a) immediately upon being served
                                            personally or electronically, (b) two days after being deposited with the postal service if
                                            served
                                            by
                                            registered mail, or (c) the following day after being deposited with an
                                            overnight
                                            courier.
                                        </p>
                                    </li>
                                    <li class="lh"><span>Indemnification</span><br>
                                    </li>
                                    <li value="29"><span>Except to the extent paid in settlement from any applicable
                                            insurance
                                            policies, and to the extent permitted by applicable law, each Party agrees
                                            to
                                            indemnify and hold harmless the other Party, and its respective</span>
                                        affiliates,
                                        officers, agents, employees, and permitted successors and assigns against any
                                        and
                                        all
                                        claims, losses, damages, liabilities, penalties, punitive damages, expenses,
                                        reasonable
                                        legal fees and costs of any kind or amount whatsoever, which result from or
                                        arise
                                        out of
                                        any act or omission of the indemnifying party, its respective affiliates,
                                        officers,
                                        agents, employees, and permitted successors and assigns that occurs in
                                        connection
                                        with
                                        this Agreement. This indemnification will survive the termination of this
                                        Agreement.<br>
                                    </li>
                                    <li class="lh"><span>Modification of</span><strong><u>
                                            </u></strong><strong><u>Agreement</u></strong><br>
                                    </li>
                                    <li value="30"><span>Any amendment or modification of this</span> Agreement or
                                        additional
                                        obligation assumed by either Party in connection with this Agreement will only
                                        be
                                        binding if evidenced in writing signed by each Party or an authorized
                                        representative
                                        of
                                        each Party.<br>
                                    </li>
                                    <li class="lh"><span>Time of the Essence</span><br>
                                    </li>
                                    <li value="31"><span>Time is of the essence in this</span> Agreement. No extension
                                        or
                                        variation of this Agreement will operate as a waiver of this provision.<br>
                                    </li>
                                    <li class="lh"><span>Assignment</span><br>
                                    </li>
                                    <li value="32"><span>The</span> Consultant will not voluntarily, or by operation of
                                        law,
                                        assign or otherwise transfer its obligations under this Agreement without the
                                        prior
                                        written consent of the Client.<br>
                                    </li>
                                    <li class="lh"><span>Entire Agreement</span><br>
                                    </li>
                                    <li value="33"><span>It is agreed that there is no representation, warranty,
                                            collateral
                                            agreement or condition affecting this</span> Agreement except as expressly
                                        provided
                                        in this Agreement.<br>
                                    </li>
                                    <!-- <li class="lh"><span>Enurement</span><br>
                                    </li>
                                    <li value="34"><span>This</span> Agreement will enure to the benefit of and be
                                        binding
                                        on
                                        the Parties and their respective heirs, executors, administrators and permitted
                                        successors and assigns.<br>
                                    </li> -->
                                    <div class="pagebreakafter"></div>
                                    <div class="pagebreakbefore"></div>
                                    <li class="lh pagebreakbefore"><span>Titles/Headings</span><br>
                                    </li>
                                    <li value="35"><span>Headings are inserted for the convenience of the Parties only
                                            and
                                            are
                                            not to be considered when interpreting this</span> Agreement.<br>
                                    </li>
                                    <!-- <li class="lh"><span>Gender</span><br>
                                    </li>
                                    <li value="36"><span>Words in the singular mean and include the plural and vice
                                            versa.
                                            Words
                                            in the masculine mean and include the feminine and vice versa.</span><br>
                                    </li> -->
                                    <li class="lh"><span>Governing Law</span><br>
                                    </li>
                                    <li value="37"><span>This</span> Agreement will be governed by and construed in
                                        accordance
                                        with the laws of the Commonwealth of Massachusetts.<br>
                                    </li>
                                    <li class="lh"><span>Severability</span><br>
                                    </li>
                                    <li value="38"><span>In the event that any of the provisions of this</span>
                                        Agreement
                                        are
                                        held to be invalid or unenforceable in whole or in part, all other provisions
                                        will
                                        nevertheless continue to be valid and enforceable with the invalid or
                                        unenforceable
                                        parts severed from the remainder of this Agreement.<br>
                                    </li>
                                    <li class="lh"><span>Waiver</span><br>
                                    </li>
                                    <li value="39"><span>The waiver by either Party of a breach, default, delay or
                                            omission
                                            of
                                            any of the provisions of this</span> Agreement by the other Party will not
                                        be
                                        construed as a waiver of any subsequent breach of the same or other
                                        provisions.<br>
                                    </li>
                                </ol>
                            </div>
                            <div class=" signature keepTogether">
                                <p><strong>IN WITNESS WHEREOF</strong> the Parties have duly affixed their signatures
                                    under
                                    hand
                                    and seal on this <span class="paramField">_{{
                                    contractData.contractSigningDay.value || '__________' }}_</span> day of
                                <span class="paramField">_{{ contractData.contractSigningMonth.value || '_________________'
                                    }}_</span>, <span class="paramField">_{{ contractData.contractSigningYear.value || '__________'
                                    }}_</span>.
                                </p>
                                <div class="signatures US MA">
                                    <div class="signatureParty">
                                        <div class="signatureDetail esignature-1:customerSignature">
                                            <div class="signatureEntity individual esignatureEntity">
                                                <div class="signatureName">
                    
                                                </div>
                                                <div class="signatureLine">
                                                    _______________________________
                                                </div>
                                                <div class="name">
                                                    <span class="name">
                                                        <span v-if="contractData.client1.value" class="paramField">{{ '_' +
                                                            contractData.client1.value + '_' }}</span>
                                                        <span v-else>______________________</span>
                                                    </span> (Client)
                                                </div>
                                            </div>
                                            <div class="witnesses">
                    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="signatureParty">
                                        <div class="signatureDetail esignature-1:providerSignature">
                                            <div class="signatureEntity individual esignatureEntity">
                                                <div class="signatureName">
                    
                                                </div>
                                                <div class="signatureLine">
                                                    _______________________________
                                                </div>
                                                <div class="name">
                                                    <span v-if="contractData.consultant1.value" class="paramField">{{ '_' +
                                                        contractData.consultant1.value + '_' }}</span>
                                                    <span v-else>______________________</span>
                                                    (Consultant)
                                                </div>
                                            </div>
                                            <div class="witnesses">
                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-theme-provider>
    <v-dialog v-model="contractSettingsDialog" :fullscreen="!smAndUp">
        <v-card>
            <v-card-title class="pickup-color sleigher-font">Mike's High Performance Software Contract
                Settings</v-card-title>
            <v-card-text>
                <div v-for="(setting, settingKey) in contractData" :key="setting.name">
                    <v-text-field v-if="setting.type === 'String'" persistent-placeholder persistent-clear clearable :label="setting.name" v-model="contractData[settingKey].value" variant="outlined"></v-text-field>
                    <div v-if="setting.type === 'Array'" class="d-flex flex-column">
                        <div class="d-flex justify-center align-center">
                            <v-text-field disabled :label="setting.name" variant="underlined"></v-text-field>
                            <v-btn size="medium" icon="mdi-plus-circle-outline" @click="setting.value?.push('')"></v-btn>
                        </div>
                        <v-text-field
                        v-for="(item, i) in setting.value"
                        clearable
                        v-model="contractData[settingKey].value[i]"
                        variant="outlined"
                        :key="i"
                        prepend-icon="mdi-minus-circle-outline"
                        @click:prepend="contractData[settingKey].value.splice(i, 1)"
                        ></v-text-field>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn class="pickup-color sleigher-font" block @click="closeSettings">Dismiss</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { reactive, ref } from 'vue'
import { useDisplay } from 'vuetify'
export default {
name: 'Contract',
props: {
    height: {
        type: Number,
        required: false,
        default: null
    },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
        type: Number,
        required: false,
        default: null
    },
        verticalOverflow: {
            type: Boolean,
            required: false,
            default: false
        },
        uniformFont: {
            type: Boolean,
            required: false,
            default: false
        },
      homeFunction: {
        type: Function,
        required: false,
        default: () => {}
      }
},
components: {
},
setup(props) {
    const contractData = reactive({
        client1: { value: '', name: 'Client', type: 'String' },
        client2: { value: '', name: 'Client (2)', type: 'String' },
        clientAddress1: { value: '', name: 'Client Address', type: 'String' },
        clientAddress2: { value: '', name: 'Client Address (2)', type: 'String' },

        consultant1: { value: '', name: 'Consultant', type: 'String' },
        consultant2: { value: '', name: 'Consultant (2)', type: 'String' },
        consultantAddress1: { value: '', name: 'Consultant Address', type: 'String' },
        consultantAddress2: { value: '', name: 'Consultant Address (2)', type: 'String' },

        contractDay: { value: '', name: 'Contract Date (Day)', type: 'String' },
        contractMonth: { value: '', name: 'Contract Date (Month)', type: 'String' },
        contractYear: { value: '', name: 'Contract Date (Year)', type: 'String' },
        contractServices: {
            value: [''],
            name: 'Contract Services',
            type: 'Array'
        },
        contractHourlyRate: { value: '', name: 'Hourly Rate', type: 'String' },
        
        contractSigningDay: { value: '', name: 'Contract Signing Date (Day)', type: 'String' },
        contractSigningMonth: { value: '', name: 'Contract Signing Date (Month)', type: 'String' },
        contractSigningYear: { value: '', name: 'Contract Signing Date (Year)', type: 'String' }
    })

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()

    // Settings Dialog Data
    const contractSettingsDialog = ref(false)
    
    // Functions
    const goHome = () => {
        props.homeFunction()
    }

    const openSettings = () => {
        contractSettingsDialog.value = true
    }

    const closeSettings = () => {
        contractSettingsDialog.value = false
    }

    return {
    contractData,
    // Dialog Data
    contractSettingsDialog,
    // Functions
    goHome,
    openSettings,
    closeSettings,
    // Window Size Management
    smAndUp,
    mdAndUp
    }
}
}
</script>

<style>
#contract {
    background-color: #fff;
    border: solid 1px #808285;
    padding: 1px;
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
}

#outputPage {
    position: relative;
    padding: 2em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    overflow-x: hidden;
}

.paramField {
    text-decoration: underline;
}

.template_SERVAG {
    font-family: minion-pro, sans-serif;
    font-size: 12pt;
    line-height: 15pt;
    color: #000;
    text-align: left
}

.template_SERVAG.outputVersion2 {
    padding-top: 0
}

.template_SERVAG h1,
.template_SERVAG h2 {
    font-family: myriad-pro, sans-serif;
    text-transform: uppercase
}

.template_SERVAG h1 {
    font-size: 18pt;
    color: #000;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    margin: 14px 0 40px
}

.template_SERVAG h2 {
    font-size: 12pt
}

.template_SERVAG table {
    width: 100%;
    border: 0;
    table-layout: fixed;
    word-wrap: break-word;
    border-collapse: collapse
}

.template_SERVAG table td {
    padding: 10px;
    border: 1px solid #000
}

.template_SERVAG .header,
.template_SERVAG .footer,
.template_SERVAG .firstHeader,
.template_SERVAG .firstFooter {
    font-family: myriad-pro, sans-serif;
    font-size: 10pt;
    color: #bcbec0;
    position: relative
}

.template_SERVAG .header {
    border-bottom: 0.5px solid #bcbec0;
    box-sizing: border-box;
    vertical-align: bottom
}

.template_SERVAG .pageNumbers {
    position: absolute;
    right: 0
}

.template_SERVAG ol {
    list-style: none;
    counter-reset: item;
    padding: 0;
    margin: 20px 0
}

.template_SERVAG ol>li:not(.lh):not(.lhl) {
    position: relative;
    margin: 10px 0 15px 2em !important;
    padding: 0
}

.template_SERVAG ol>li:not(.lh):not(.lhl)::before {
    counter-increment: item;
    content: counter(item) ". ";
    font-weight: bold;
    position: absolute;
    left: -2em;
    width: 2em;
    text-align: right;
    padding-right: 5px;
    box-sizing: border-box
}

.template_SERVAG ol>li:not(.lh):not(.lhl) ul li {
    padding-left: 5px;
    margin-top: 10px;
    list-style-type: disc
}

.template_SERVAG ol.list-style-type-upper-alpha>li:not(.lh):not(.lhl) {
    list-style: none
}

.template_SERVAG ol.list-style-type-upper-alpha>li:not(.lh):not(.lhl)::before {
    content: counter(item, upper-alpha) ". "
}

.template_SERVAG ol.list-style-type-lower-alpha>li:not(.lh):not(.lhl) {
    list-style: none
}

.template_SERVAG ol.list-style-type-lower-alpha>li:not(.lh):not(.lhl)::before {
    content: counter(item, lower-alpha) ". "
}

.template_SERVAG ol.list-style-type-upper-roman>li:not(.lh):not(.lhl) {
    list-style: none
}

.template_SERVAG ol.list-style-type-upper-roman>li:not(.lh):not(.lhl)::before {
    content: counter(item, upper-roman) ". "
}

.template_SERVAG ol.list-style-type-lower-roman>li:not(.lh):not(.lhl) {
    list-style: none
}

.template_SERVAG ol.list-style-type-lower-roman>li:not(.lh):not(.lhl)::before {
    content: counter(item, lower-roman) ". "
}

.template_SERVAG ol.recital>li:not(.lh):not(.lhl)::before {
    content: counter(item, upper-alpha) ". "
}

.template_SERVAG li ol>li:not(.lh):not(.lhl)::before {
    content: counter(item, lower-alpha) ". "
}

.template_SERVAG li ol>li:not(.lh):not(.lhl) ol>li:not(.lh):not(.lhl)::before {
    content: counter(item, lower-roman) ". "
}

.template_SERVAG li.lh,
.template_SERVAG li.lhl {
    font-family: myriad-pro, sans-serif;
    text-transform: uppercase;
    font-size: 12pt;
    font-weight: bold;
    color: #000;
    margin: 20px 0 10px;
    padding: 0
}

.template_SERVAG li.lh,
.template_SERVAG li.lhl,
.template_SERVAG ol.recital>li {
    list-style: none
}

.template_SERVAG .lh u {
    text-decoration: none
}

.template_SERVAG p {
    margin: 20px 0
}

.template_SERVAG td>p {
    margin: 0
}

.template_SERVAG .parties {
    display: flex;
    flex-direction: row
}

.template_SERVAG .parties h2 {
    font-family: myriad-pro, sans-serif;
    text-transform: uppercase;
    font-size: 12pt;
    color: #000;
    font-weight: 700;
    text-align: center;
    margin: 0 0 20px
}

.template_SERVAG .parties>div {
    border: 0.5px solid #bcbec0;
    text-align: center;
    padding: 15px;
    width: 43%;
}

.template_SERVAG .parties>div:first-child {
    margin-right: 30px
}

.template_SERVAG .parties .detail~.detail::before {
    content: "-AND-";
    font-weight: bold;
    margin: 15px;
    display: block
}

.template_SERVAG .parties .detail span {
    display: block;
    margin-top: 10px
}

.template_SERVAG .parties .titleSentence {
    margin-top: 15px;
    display: block
}

.template_SERVAG .partiesContainer {
    margin: 20px 0;
    display: table;
    width: 100%
}

.template_SERVAG .thirdBox .parties>div {
    width: 33.33%
}

.template_SERVAG .body {
    border: 0.5px solid #bcbec0;
    border-style: solid none;
    box-decoration-break: slice;
    padding-bottom: 16px
}

.template_SERVAG .signature {
    padding-top: 20px
}

.template_SERVAG .signatures {
    width: 100%;
    display: table;
    direction: rtl;
    margin-top: 40px
}

.template_SERVAG .signatureParty {
    width: 100%;
    display: table
}

.template_SERVAG .signatureDetail {
    display: table-row
}

.template_SERVAG .signatureEntity,
.template_SERVAG .witnesses {
    display: table-cell;
    direction: ltr;
    width: 50%;
    vertical-align: middle;
    padding: 0 0 20px
}

.template_SERVAG .witnessName,
.template_SERVAG .signatureEntity .name {
    padding-top: 12px
}

.template_SERVAG .parties .detail>.name,
.template_SERVAG .parties .detail>.name+.address,
.template_SERVAG td>p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    overflow-x: hidden
}

.template_SERVAG .seal,
.template_SERVAG .repSignature {
    margin-top: 20px
}

.template_SERVAG .esignatureEntity .signatureName {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    border-bottom: 1px solid;
    min-width: 226px;
    display: inline-block
}

.template_SERVAG .esignatureEntity .signatureName+.signatureLine {
    display: none
}

.template_SERVAG .esignatureEntity .repSignature .repPrompt {
    margin-right: 5px
}

.template_SERVAG .esignatureEntity .seal,
.template_SERVAG .esignatureEntity .repSignature {
    margin: 0
}

.template_SERVAG .repSignature div {
    display: inline
}

.LD body #wrapper .contract .template_SERVAG ul li,
.LD body #wrapper #contract .template_SERVAG ul li {
    list-style: disc
}

@media print {
    #contract {
        border: none !important;
        overflow: auto;
        height: auto;
    }

    #outputPage {
        overflow: auto;
        height: auto;
    }

    .pagebreakbefore {
        padding-top: 32px;
        break-before: always;
    }

    .pagebreakafter {
        padding-bottom: 96px;
        break-after: always;
    }

    .no-print {
        visibility: hidden;
    }
}
</style>