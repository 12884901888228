<template>
    <v-img class="pa-0" :src="require('@/assets/images/mhps-logo-1956-chevy-pickup.jpg')" :height="height" cover>
    </v-img>
  </template>
  
  <script>
  
  export default {
    name: 'Home',
    props: {
        height: {
            type: Number,
            required: false,
            default: null
        }
    },
    components: {
    },
    setup() {
      return {}
    }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>