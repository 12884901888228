<template>
    <div class="d-flex flex-column justify-center align-center"
        :style="{ position: 'fixed', top: '30px', right: '10px', transform: 'translate(-50%, -50%)', 'z-index': 1000 }">
        <v-btn class="no-print" size="medium" icon="mdi-home-circle-outline" @click="goHome"></v-btn>
        <v-btn class="no-print mt-2" size="medium" icon="mdi-cog-outline" @click="openSettings"></v-btn>
    </div>
    <v-theme-provider theme="light">
        <div class="d-flex flex-column align-center justify-center pt-3">
            <div id="contract"
                :style="{ 'user-select': 'none', 'min-width': '700px' }">
                <GradientLogo
                    :unlimitedSize="true"
                    logoText="MHPS"
                    :maxFontSize="Number(logoData.fontSize.value)"
                    :hexColor="logoData.topColor.value"
                    :hexBottomColor="logoData.bottomColor.value"
                    class="ml-3 mr-12 py-2" />
            </div>
        </div>
    </v-theme-provider>
    <v-dialog v-model="logoSettingsDialog" :fullscreen="!smAndUp">
        <v-card width="400">
            <v-card-title class="pickup-color sleigher-font">Logo Settings</v-card-title>
            <v-card-text>
                <div v-for="(setting, settingKey) in logoData" :key="setting.name">
                    <v-text-field v-if="setting.type === 'String'" persistent-placeholder persistent-clear clearable :label="setting.name" v-model="logoData[settingKey].value" variant="outlined"></v-text-field>
                    <div v-if="setting.type === 'Array'" class="d-flex flex-column">
                        <div class="d-flex justify-center align-center">
                            <v-text-field disabled :label="setting.name" variant="underlined"></v-text-field>
                            <v-btn size="medium" icon="mdi-plus-circle-outline" @click="setting.value?.push('')"></v-btn>
                        </div>
                        <v-text-field
                        v-for="(item, i) in setting.value"
                        clearable
                        v-model="logoData[settingKey].value[i]"
                        variant="outlined"
                        :key="i"
                        prepend-icon="mdi-minus-circle-outline"
                        @click:prepend="logoData[settingKey].value.splice(i, 1)"
                        ></v-text-field>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn class="pickup-color sleigher-font" block @click="closeSettings">Dismiss</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { reactive, ref } from 'vue'
import { useDisplay } from 'vuetify'
import GradientLogo from './logos/GradientLogo.vue'
export default {
name: 'LogoPrinter',
props: {
    height: {
        type: Number,
        required: false,
        default: null
    },
        isDarkTheme: {
            type: Boolean,
            required: false,
            default: true
        },
        width: {
        type: Number,
        required: false,
        default: null
    },
        verticalOverflow: {
            type: Boolean,
            required: false,
            default: false
        },
        uniformFont: {
            type: Boolean,
            required: false,
            default: false
        },
      homeFunction: {
        type: Function,
        required: false,
        default: () => {}
      }
},
components: {
    GradientLogo
},
setup(props) {
    const logoData = reactive({
        fontSize: { value: '700', name: 'Font Size', type: 'String' },
        topColor: { value: '#0883AE', name: 'Top Color (Hex)', type: 'String' },
        bottomColor: { value: '#FFF', name: 'Bottom Color (Hex)', type: 'String' }
    })

    // Window Size Mgmt
    const { mdAndUp, smAndUp } = useDisplay()

    // Settings Dialog Data
    const logoSettingsDialog = ref(false)
    
    // Functions
    const goHome = () => {
        props.homeFunction()
    }

    const openSettings = () => {
        logoSettingsDialog.value = true
    }

    const closeSettings = () => {
        logoSettingsDialog.value = false
    }

    return {
    logoData,
    // Dialog Data
    logoSettingsDialog,
    // Functions
    goHome,
    openSettings,
    closeSettings,
    // Window Size Management
    smAndUp,
    mdAndUp
    }
}
}
</script>

<style>
#contract {
    background-color: #fff;
    border: solid 1px #808285;
    padding: 1px;
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
}

#outputPage {
    position: relative;
    padding: 2em;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    overflow-x: hidden;
}

.paramField {
    text-decoration: underline;
}

.template_SERVAG {
    font-family: minion-pro, sans-serif;
    font-size: 12pt;
    line-height: 15pt;
    color: #000;
    text-align: left
}

.template_SERVAG.outputVersion2 {
    padding-top: 0
}

.template_SERVAG h1,
.template_SERVAG h2 {
    font-family: myriad-pro, sans-serif;
    text-transform: uppercase
}

.template_SERVAG h1 {
    font-size: 18pt;
    color: #000;
    font-weight: 700;
    text-align: center;
    line-height: normal;
    margin: 14px 0 40px
}

.template_SERVAG h2 {
    font-size: 12pt
}

.template_SERVAG table {
    width: 100%;
    border: 0;
    table-layout: fixed;
    word-wrap: break-word;
    border-collapse: collapse
}

.template_SERVAG table td {
    padding: 10px;
    border: 1px solid #000
}

.template_SERVAG .header,
.template_SERVAG .footer,
.template_SERVAG .firstHeader,
.template_SERVAG .firstFooter {
    font-family: myriad-pro, sans-serif;
    font-size: 10pt;
    color: #bcbec0;
    position: relative
}

.template_SERVAG .header {
    border-bottom: 0.5px solid #bcbec0;
    box-sizing: border-box;
    vertical-align: bottom
}

.template_SERVAG .pageNumbers {
    position: absolute;
    right: 0
}

.template_SERVAG ol {
    list-style: none;
    counter-reset: item;
    padding: 0;
    margin: 20px 0
}

.template_SERVAG ol>li:not(.lh):not(.lhl) {
    position: relative;
    margin: 10px 0 15px 2em !important;
    padding: 0
}

.template_SERVAG ol>li:not(.lh):not(.lhl)::before {
    counter-increment: item;
    content: counter(item) ". ";
    font-weight: bold;
    position: absolute;
    left: -2em;
    width: 2em;
    text-align: right;
    padding-right: 5px;
    box-sizing: border-box
}

.template_SERVAG ol>li:not(.lh):not(.lhl) ul li {
    padding-left: 5px;
    margin-top: 10px;
    list-style-type: disc
}

.template_SERVAG ol.list-style-type-upper-alpha>li:not(.lh):not(.lhl) {
    list-style: none
}

.template_SERVAG ol.list-style-type-upper-alpha>li:not(.lh):not(.lhl)::before {
    content: counter(item, upper-alpha) ". "
}

.template_SERVAG ol.list-style-type-lower-alpha>li:not(.lh):not(.lhl) {
    list-style: none
}

.template_SERVAG ol.list-style-type-lower-alpha>li:not(.lh):not(.lhl)::before {
    content: counter(item, lower-alpha) ". "
}

.template_SERVAG ol.list-style-type-upper-roman>li:not(.lh):not(.lhl) {
    list-style: none
}

.template_SERVAG ol.list-style-type-upper-roman>li:not(.lh):not(.lhl)::before {
    content: counter(item, upper-roman) ". "
}

.template_SERVAG ol.list-style-type-lower-roman>li:not(.lh):not(.lhl) {
    list-style: none
}

.template_SERVAG ol.list-style-type-lower-roman>li:not(.lh):not(.lhl)::before {
    content: counter(item, lower-roman) ". "
}

.template_SERVAG ol.recital>li:not(.lh):not(.lhl)::before {
    content: counter(item, upper-alpha) ". "
}

.template_SERVAG li ol>li:not(.lh):not(.lhl)::before {
    content: counter(item, lower-alpha) ". "
}

.template_SERVAG li ol>li:not(.lh):not(.lhl) ol>li:not(.lh):not(.lhl)::before {
    content: counter(item, lower-roman) ". "
}

.template_SERVAG li.lh,
.template_SERVAG li.lhl {
    font-family: myriad-pro, sans-serif;
    text-transform: uppercase;
    font-size: 12pt;
    font-weight: bold;
    color: #000;
    margin: 20px 0 10px;
    padding: 0
}

.template_SERVAG li.lh,
.template_SERVAG li.lhl,
.template_SERVAG ol.recital>li {
    list-style: none
}

.template_SERVAG .lh u {
    text-decoration: none
}

.template_SERVAG p {
    margin: 20px 0
}

.template_SERVAG td>p {
    margin: 0
}

.template_SERVAG .parties {
    display: flex;
    flex-direction: row
}

.template_SERVAG .parties h2 {
    font-family: myriad-pro, sans-serif;
    text-transform: uppercase;
    font-size: 12pt;
    color: #000;
    font-weight: 700;
    text-align: center;
    margin: 0 0 20px
}

.template_SERVAG .parties>div {
    border: 0.5px solid #bcbec0;
    text-align: center;
    padding: 15px;
    width: 43%;
}

.template_SERVAG .parties>div:first-child {
    margin-right: 30px
}

.template_SERVAG .parties .detail~.detail::before {
    content: "-AND-";
    font-weight: bold;
    margin: 15px;
    display: block
}

.template_SERVAG .parties .detail span {
    display: block;
    margin-top: 10px
}

.template_SERVAG .parties .titleSentence {
    margin-top: 15px;
    display: block
}

.template_SERVAG .partiesContainer {
    margin: 20px 0;
    display: table;
    width: 100%
}

.template_SERVAG .thirdBox .parties>div {
    width: 33.33%
}

.template_SERVAG .body {
    border: 0.5px solid #bcbec0;
    border-style: solid none;
    box-decoration-break: slice;
    padding-bottom: 16px
}

.template_SERVAG .signature {
    padding-top: 20px
}

.template_SERVAG .signatures {
    width: 100%;
    display: table;
    direction: rtl;
    margin-top: 40px
}

.template_SERVAG .signatureParty {
    width: 100%;
    display: table
}

.template_SERVAG .signatureDetail {
    display: table-row
}

.template_SERVAG .signatureEntity,
.template_SERVAG .witnesses {
    display: table-cell;
    direction: ltr;
    width: 50%;
    vertical-align: middle;
    padding: 0 0 20px
}

.template_SERVAG .witnessName,
.template_SERVAG .signatureEntity .name {
    padding-top: 12px
}

.template_SERVAG .parties .detail>.name,
.template_SERVAG .parties .detail>.name+.address,
.template_SERVAG td>p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    overflow-x: hidden
}

.template_SERVAG .seal,
.template_SERVAG .repSignature {
    margin-top: 20px
}

.template_SERVAG .esignatureEntity .signatureName {
    font-family: "Dancing Script", cursive;
    font-size: 24px;
    border-bottom: 1px solid;
    min-width: 226px;
    display: inline-block
}

.template_SERVAG .esignatureEntity .signatureName+.signatureLine {
    display: none
}

.template_SERVAG .esignatureEntity .repSignature .repPrompt {
    margin-right: 5px
}

.template_SERVAG .esignatureEntity .seal,
.template_SERVAG .esignatureEntity .repSignature {
    margin: 0
}

.template_SERVAG .repSignature div {
    display: inline
}

.LD body #wrapper .contract .template_SERVAG ul li,
.LD body #wrapper #contract .template_SERVAG ul li {
    list-style: disc
}

@media print {
    #contract {
        border: none !important;
        overflow: auto;
        height: auto;
    }

    #outputPage {
        overflow: auto;
        height: auto;
    }

    .pagebreakbefore {
        padding-top: 32px;
        break-before: always;
    }

    .pagebreakafter {
        padding-bottom: 96px;
        break-after: always;
    }

    .no-print {
        visibility: hidden;
    }
}
</style>